<template>
  <div class="outer">
    <h1>About {{ hotel.name }}</h1>
    <p>{{ hotel.description.content }}</p>
    <div class="tw-flex tw-flex-col">
      <div
        class="tw-font-poppins-semi-bold tw-text-xl tw-font-bold tw-text-left tw-mt-5"
      >
        Amenities
      </div>
      <div class="amenities-container">
        <amenities-icon
          class="amenity"
          v-for="item in amenities"
          :key="item.description"
          :description="item.description"
          ><Checkmark />
        </amenities-icon>
      </div>
    </div>
    <div class="location">
      <h2>Location</h2>
      <p>{{ hotel.name }}</p>
      <p>{{ hotel.address.content }}</p>
      <div class="tw-my-8 ">
        <GmapMap
          :center="{
            lat: parseFloat(hotel.latitude),
            lng: parseFloat(hotel.longitude)
          }"
          :zoom="19"
          style="width:100%;  height: 244px;"
        >
          <GmapMarker
            :position="{
              lat: parseFloat(hotel.latitude),
              lng: parseFloat(hotel.longitude)
            }"
        /></GmapMap>
        <!-- <img :src="imgSrc" alt="map" class="tw-w-full" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import AmenitiesIcon from '../AmenitiesIcon.vue';
import Checkmark from '@/assets/hotelDetails/checkmark.svg';
export default {
  props: {
    hotel: Object,
    amenities: Array,
    imgSrc: String
  },
  components: {
    AmenitiesIcon,
    Checkmark
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  text-align: left;
  display: flex;
}

h1,
h2 {
  font-family: PoppinsSemiBold;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #28262e;
  margin: 12px 0;
  text-align: left;
}

p {
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #747378;
  margin: 24px 0;
}

.outer {
  padding: 32px;
}

.location {
  margin-top: 48px;
}

.amenities-container {
  .amenity {
    flex-grow: 1;
    min-width: 33%;
    max-width: 33%;
  }
  display: flex;
  flex-wrap: wrap;
}
</style>
