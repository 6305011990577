<template>
  <div class="outer">
    <div class="categories">
      <h1>Categories</h1>
      <div class="tw-flex categories-container">
        <!-- <div class="category-option">
          All Photos
        </div> -->
        <span
          v-for="(imageArray, propertyName, index) in groupImages"
          v-bind:key="propertyName"
        >
          <div
            class="category-option"
            :style="{
              background:
                `
          linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 48.59%,
      rgba(0, 0, 0, 0.8) 100%
          ),    
          url(${imageArray[0].path.replace('medium', 'xl')}),
          url(${imageArray[0].path})` +
                (imageArray[1] ? `,url(${imageArray[1].path}) ` : ``),
            }"
            v-if="index < 5"
            @click="
              () => {
                selectedCategory = index;
                selectedImageIndex = null;
                page = 1;
              }
            "
            v-bind:class="{ categoryActive: index === selectedCategory }"
          >
            {{
              mapCategoryNames.get(propertyName)
                ? mapCategoryNames.get(propertyName)
                : propertyName
            }}
            ({{ imageArray.length }})
          </div></span
        >
      </div>
    </div>
    <!-- <hr /> -->
    <div class="selected-image" v-if="typeof selectedImageIndex === 'number'">
      <div class="close" @click="selectedImageIndex = null"><CloseIcon /></div>
      <span>
        <div
          v-if="selectedImageIndex > 0"
          class="prev"
          @click="selectedImageIndex -= 1"
        >
          <NextIcon />
        </div>
        <div
          v-if="selectedImageIndex < images.length - 1"
          class="next"
          @click="selectedImageIndex += 1"
        >
          <NextIcon /></div
      ></span>
      <ImageQualityContainer
        :src="images[page * 8 - 8 + selectedImageIndex].path"
      />
    </div>
    <div class="images">
      <div
        @click="selectedImageIndex = index"
        v-for="(item, index) in images.slice(page * 8 - 8, page * 8)"
        v-bind:key="item.path"
        v-bind:class="{
          active:
            typeof selectedImageIndex === 'number'
              ? item.path === images[page * 8 - 8 + selectedImageIndex].path
              : false,
        }"
      >
        <ImageQualityContainer
          style="width: 100%; height: 160px; min-width: 100%; min-height: 160px"
          :src="item.path"
        />
      </div>
    </div>
    <div class="showing">
      <div>
        Showing
        {{ page * 8 - 7 }}
        <template v-if="page * 8 < images.length">- {{ page * 8 }}</template>
        <template v-else-if="images.length > 1">- {{ images.length }}</template>
        of {{ images.length }} images
      </div>
      <div class="tw-flex tw-ml-auto tw-gap-3 tw-select-none">
        <div
          @click="
            () => {
              selectedImageIndex = null;
              if (page > 1) page--;
            }
          "
          class="
            tw-cursor-pointer
            arrow
            tw-bg-paragraph
            tw-bg-opacity-10
            tw-rounded-full
            tw-flex
            tw-items-center
            tw-justify-center
            hover:tw-bg-opacity-5
          "
        >
          <v-icon color="black">mdi-chevron-left </v-icon>
        </div>
        <div
          v-for="index in pagesCount"
          :key="index"
          class="page-number"
          v-bind:class="{ activePage: index === page }"
          @click="
            () => {
              selectedImageIndex = null;
              page = index;
            }
          "
        >
          {{ index }}
        </div>
        <div
          @click="
            () => {
              selectedImageIndex = null;
              if (pagesCount > page) page++;
            }
          "
          class="
            arrow
            tw-cursor-pointer
            tw-bg-paragraph
            tw-bg-opacity-10
            tw-rounded-full
            tw-flex
            tw-items-center
            tw-justify-center
            hover:tw-bg-opacity-5
          "
        >
          <v-icon color="black">mdi-chevron-right </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageQualityContainer from '../ImageQualityContainer.vue';
import NextIcon from '@/assets/hotelDetails/next.svg';
import CloseIcon from '@/assets/hotelDetails/close.svg';

export default {
  data: () => ({
    selectedCategory: 0,
    selectedImageIndex: null,
    page: 1,
    mapCategoryNames: new Map([
      [ 'HAB', 'Rooms' ],
      [ 'DEP', 'Sports' ],
      [ 'COM', 'Lobby' ],
      [ 'GEN', 'General view' ],
      [ 'RES', 'Restaurants' ],
      [ 'BAR', 'Bar' ],
      [ 'PIS', 'Pool' ],
      [ 'TER', 'Terrace' ],
      [ 'CON', 'Conferences' ],
    ]),
  }),
  props: {
    hotel: Object,
  },
  emits: [],
  components: {
    ImageQualityContainer,
    NextIcon,
    CloseIcon,
  },
  methods: {
    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    },
  },
  computed: {
    pagesCount: {
      get() {
        return this.roundUp(this.images.length / 8, 0);
      },
    },
    images: {
      get() {
        return this.groupImages[
          Object.keys(this.groupImages)[this.selectedCategory]
        ];
      },
    },
    groupImages: {
      get() {
        const prop = 'imageTypeCode';
        var grouped = {};
        for (var i = 0; i < this.hotel.images.length; i++) {
          var p = this.hotel.images[i][prop];
          if (!grouped[p]) {
            grouped[p] = [];
          }
          grouped[p].push(this.hotel.images[i]);
          grouped[p] = grouped[p].filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return (
              index ===
              grouped[p].findIndex(obj => {
                return JSON.stringify(obj) === _thing;
              })
            );
          });
        }
        return grouped;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  padding: 32px;
}

.categoryActive {
  -moz-box-shadow: inset 0px 0px 0px 2px #d62a53;
  -webkit-box-shadow: inset 0px 0px 0px 2px #d62a53;
  box-shadow: inset 0px 0px 0px 2px #d62a53;
}

.active {
  display: relative;
  > :before {
    content: '';

    /* Expand element */
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    border-radius: 4px;
    bottom: 0px;

    -moz-box-shadow: inset 0px 0px 0px 2px #d62a53;
    -webkit-box-shadow: inset 0px 0px 0px 2px #d62a53;
    box-shadow: inset 0px 0px 0px 2px #d62a53;

    /* Disable click events */
    pointer-events: none;
  }
}
.categories {
  padding-bottom: 24px;
  h1 {
    font-family: PoppinsSemiBold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #28262e;
    text-align: left;
    margin: 16px 0 24px 0;
  }

  .category-option {
    width: 160px;
    cursor: pointer;
    height: 100px;
    margin-right: 16px;
    background-size: cover !important;
    color: white;
    display: flex;
    flex-direction: column-reverse;
    font-family: MontserratSemiBold;
    font-style: normal;
    font-size: 14px;
    min-width: 160px;
    border-radius: 4px;
    line-height: 14px;
    letter-spacing: -0.04em;
    text-align: start;
    padding: 12px;
    user-select: none;
  }
}

.selected-image {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .prev {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    left: 0;
    svg {
      transform: rotate(180deg);
    }
  }
  .next {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    right: 0;
  }
  .prev,
  .next {
    display: flex;
    height: 100%;
    width: 72px;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 8px;
    z-index: 999;
    top: 0;
    opacity: 0;
    transition: 0.3s;
  }

  span:hover {
    .next,
    .prev {
      opacity: 1;
      cursor: pointer;
    }
  }
  img {
    width: 100%;
    min-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 9999;
  cursor: pointer;
}

.images {
  display: flex;
  margin: -4px -4px 0 -4px;
  flex-wrap: wrap;
  > div {
    margin: 4.5px;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    background-size: cover !important;
    max-width: calc(25% - 9px);
  }
}

.showing {
  width: 100%;
  text-align: start;
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  color: #28262e;
  margin-top: 28px;
}
.page-number {
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-family: PoppinsMedium;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.activePage {
  background: #d62a53;
  color: #ffffff;
}

.arrow {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.v-icon {
  font-size: 16px !important;
}

.categories-container {
  flex-wrap: wrap;
  height: 101px;
  overflow-y: hidden;
}
</style>
