<template>
  <div
    class="section bg-image hidden-xs-only"
    :style="backgroundImage"
  >
    <div class="setBackground">
       <div class="tw-flex tw-flex-col">
        <breadcrumbs :city="city" class="tw-mt-32 tw-z-10 tw-py-0 tw-ml-16"/>
    </div>
      <div
        class="tw-justify-center tw-min-h-screen"
      >
        <div class="tw-flex tw-flex-row tw-flex-grow tw-flex-wrap tw-justify-center">
          <div class="tw-mt-18">
            <div class="city-container">
                <h2 class="city-header">{{ city.name }}</h2>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-grow tw-flex-wrap tw-justify-center tw-items-center tw-content-center">
           <h3 class="country-header">{{ city.country }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';
import Breadcrumbs from '@/views/layouts/common/Breadcrumbs.vue';

export default {
  name: 'BookingCityTab',
  data() {
    return {
    };
  },
  props: [ 'city' ],
  computed: {
    backgroundImage() {
      return {
        backgroundImage:
          'url(' + transformImage(this.city.background_image) + ')'
      };
    },
  },
  methods: {
    ...mapMutations([ 'setDestinationBackground' ]),
    getBackground: name => ({
      backgroundImage: `url(${require('/src/assets/destinationPage/' + name)})`
    }),
    experienceSelectionHandler() {
      this.setDestinationBackground(this.city.background_image);
    },
  },
  components: {
    Breadcrumbs
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.bg-image {
  background-position: center;
  background-size: cover;
  height: 60vh;
}

.setBackground {
  background: linear-gradient(90deg, #000000 -9.62%, rgba(0, 0, 0, 0) 100%);
  height: 60vh;
}

.city-container {
  padding-bottom: 10px;
  margin: 0 7%;
  width: 100%;
}

.city-header {
  font-family: PoppinsSemiBold;
  letter-spacing: -0.4rem;
  line-height: 0.9;
  font-size: 7.25vw;
  color: white;

  @include respond-to(xl) {
    font-size: 6rem;
  }
}

.country-header {
    font-family: PoppinsLight;
    letter-spacing: -0.3vw;
    line-height: 0.9;
    font-size: 4vw;
    color: white;
    //padding: 0 0 0 3vw;
}


.min-h-screen {
  min-height: 100vh;
}
</style>

