<template>
  <div class="outer" :class="loaded ? 'loaded' : 'loading'">
    <div class="actionbar">
      <div
        class="title tw-flex tw-flex-row tw-ml-11 tw-mt-2.5 tw-text-primary tw-text-xs"
      >
        Let Balloon Take Care Of Your Booking
        <div class="add-btn tw-ml-2" @click="$emit('addItemToCart')">
          <span>
            <span class="tw-h-4 tw-px-4 tw-text-sm tw-mt-2">Add to cart</span>
          </span>
        </div>
      </div>
      <v-btn @click="$emit('togglePopup')" class="close"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#28262E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#28262E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </div>

    <div class="spinner-container">
      <div class="spinner"></div>
    </div>

    <iframe
      @load="
        () => {
          loaded = true;
        }
      "
      :src="src"
    />
    <transition name="fade" appear>
      <div
        class="modal-overlay"
        v-if="showModal"
        @click="showModal = false"
      ></div>
    </transition>
    <date-modal
      v-if="showModal"
      :item="item"
      @close="closeModal"
      @update="updateModal"
    ></date-modal>
    <div class="bottombar"></div>
    <div @click="$emit('togglePopup')" class="overlay"></div>
  </div>
</template>

<script>
import dateModal from '@/views/inspiration/tab-items/DateModal.vue';
import { mapActions } from 'vuex';
export default {
  data: function() {
    return {
      loaded: false,
      showModal: false
    };
  },
  props: {
    src: String,
    showPopup: Boolean,
    item: Object
  },
  components: {
    dateModal
  },
  created() {
    window.setTimeout(() => {
      this.loaded = true;
    }, 1000);
  },
  methods: {
    ...mapActions([ 'addExperienceToCart' ]),
    closeModal() {
      this.showModal = false;
    },
    updateModal(name, date, time = null, quantity = 1) {
      if (this.type !== 'activities') {
        quantity = 1;
      }
      this.removeActivityOrRestaurant({ name: this.name });
      this.addExperienceToCart({
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.price,
        details: { description: this.description },
        quantity: quantity,
        type: this.type,
        date
      });
      this.updateDates({ name: this.name, date, time });
      // this.drawerState = !this.drawerState;
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.outer {
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 40;
}

.actionbar {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 10vw);
  height: 56px;
  max-width: 1128px;
  background: white;
  margin-top: auto;
  display: flex;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #f2f2f2;
}
.bottombar {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 10vw);
  height: 8px;
  max-width: 1128px;
  background: white;
  margin-top: 0;
  display: flex;
  margin-bottom: 56px;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #f2f2f2;
}

iframe {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 10vw);
  height: calc(100% - 5vh - 56px - 56px);
  max-width: 1128px;

  background: #fefefe;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: rgba(0, 0, 0, 0.8);
  animation: 0.25s ease-out 0s 1 overlay;
}

@keyframes overlay {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.close {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;

  height: 48px !important;
  width: 48px !important;
  margin: 4px;
  margin-left: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
body {
  background: #20262e;
}

.spinner-container {
  width: calc(100% - 10vw);
  height: calc(100% - 5vh - 56px - 56px);
  max-width: 1128px;
  margin-left: auto;
  margin-right: auto;
  background: #fefefe;
}

.spinner,
.spinner::before,
.spinner::after {
  content: '';
  display: block;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: spin infinite linear 2s;
  position: absolute;
}
.spinner {
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}
.spinner::before {
  border-top-color: #d62a53;
  animation-duration: 4s;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}
.spinner::after {
  border-top-color: #d62a53;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
}
.loaded .spinner-container {
  display: none;
}

.loading iframe {
  display: none;
}

.add-btn {
  background: #d62a53;
  border-radius: 90px;
  height: 34px;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  margin-top: -0.15rem;
}

.add-btn:hover {
  opacity: 0.9;
}
.add-btn:active {
  opacity: 0.75;
}
</style>
