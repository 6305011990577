var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer"},[_c('div',{staticClass:"categories"},[_c('h1',[_vm._v("Categories")]),_c('div',{staticClass:"tw-flex categories-container"},_vm._l((_vm.groupImages),function(imageArray,propertyName,index){return _c('span',{key:propertyName},[(index < 5)?_c('div',{staticClass:"category-option",class:{ categoryActive: index === _vm.selectedCategory },style:({
            background:
              `
        linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 48.59%,
    rgba(0, 0, 0, 0.8) 100%
        ),    
        url(${imageArray[0].path.replace('medium', 'xl')}),
        url(${imageArray[0].path})` +
              (imageArray[1] ? `,url(${imageArray[1].path}) ` : ``),
          }),on:{"click":() => {
              _vm.selectedCategory = index;
              _vm.selectedImageIndex = null;
              _vm.page = 1;
            }}},[_vm._v(" "+_vm._s(_vm.mapCategoryNames.get(propertyName) ? _vm.mapCategoryNames.get(propertyName) : propertyName)+" ("+_vm._s(imageArray.length)+") ")]):_vm._e()])}),0)]),(typeof _vm.selectedImageIndex === 'number')?_c('div',{staticClass:"selected-image"},[_c('div',{staticClass:"close",on:{"click":function($event){_vm.selectedImageIndex = null}}},[_c('CloseIcon')],1),_c('span',[(_vm.selectedImageIndex > 0)?_c('div',{staticClass:"prev",on:{"click":function($event){_vm.selectedImageIndex -= 1}}},[_c('NextIcon')],1):_vm._e(),(_vm.selectedImageIndex < _vm.images.length - 1)?_c('div',{staticClass:"next",on:{"click":function($event){_vm.selectedImageIndex += 1}}},[_c('NextIcon')],1):_vm._e()]),_c('ImageQualityContainer',{attrs:{"src":_vm.images[_vm.page * 8 - 8 + _vm.selectedImageIndex].path}})],1):_vm._e(),_c('div',{staticClass:"images"},_vm._l((_vm.images.slice(_vm.page * 8 - 8, _vm.page * 8)),function(item,index){return _c('div',{key:item.path,class:{
        active:
          typeof _vm.selectedImageIndex === 'number'
            ? item.path === _vm.images[_vm.page * 8 - 8 + _vm.selectedImageIndex].path
            : false,
      },on:{"click":function($event){_vm.selectedImageIndex = index}}},[_c('ImageQualityContainer',{staticStyle:{"width":"100%","height":"160px","min-width":"100%","min-height":"160px"},attrs:{"src":item.path}})],1)}),0),_c('div',{staticClass:"showing"},[_c('div',[_vm._v(" Showing "+_vm._s(_vm.page * 8 - 7)+" "),(_vm.page * 8 < _vm.images.length)?[_vm._v("- "+_vm._s(_vm.page * 8))]:(_vm.images.length > 1)?[_vm._v("- "+_vm._s(_vm.images.length))]:_vm._e(),_vm._v(" of "+_vm._s(_vm.images.length)+" images ")],2),_c('div',{staticClass:"tw-flex tw-ml-auto tw-gap-3 tw-select-none"},[_c('div',{staticClass:"tw-cursor-pointer arrow tw-bg-paragraph tw-bg-opacity-10 tw-rounded-full tw-flex tw-items-center tw-justify-center hover:tw-bg-opacity-5",on:{"click":() => {
            _vm.selectedImageIndex = null;
            if (_vm.page > 1) _vm.page--;
          }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-chevron-left ")])],1),_vm._l((_vm.pagesCount),function(index){return _c('div',{key:index,staticClass:"page-number",class:{ activePage: index === _vm.page },on:{"click":() => {
            _vm.selectedImageIndex = null;
            _vm.page = index;
          }}},[_vm._v(" "+_vm._s(index)+" ")])}),_c('div',{staticClass:"arrow tw-cursor-pointer tw-bg-paragraph tw-bg-opacity-10 tw-rounded-full tw-flex tw-items-center tw-justify-center hover:tw-bg-opacity-5",on:{"click":() => {
            _vm.selectedImageIndex = null;
            if (_vm.pagesCount > _vm.page) _vm.page++;
          }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-chevron-right ")])],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }