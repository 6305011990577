<template>
  <div class="outer">
    <h1>Hotel Surroundings</h1>
    <p>Guests loved walking around the neighbourhood!</p>
    <h2 v-if="surroundings.length > 0">
      <beachPalm />
      <span class="tw-ml-1">What's Nearby</span>
    </h2>
    <div
      class="surrounding"
      v-for="surrounding in surroundings"
      :key="surrounding.number"
    >
      <span>
        {{ surrounding.description }}
      </span>
      <span v-if="surrounding.distance >= 1000" class="tw-ml-auto">
        {{ surrounding.distance / 1000 }}km
      </span>
      <span v-else class="tw-ml-auto"> {{ surrounding.distance }}m </span>
    </div>
  </div>
</template>

<script>
import beachPalm from '@/assets/hotelDetails/beach-palm.svg';

export default {
  props: {
    hotel: Object,
    surroundings: Array
  },
  components: {
    beachPalm
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.outer {
  padding: 48px 32px;
  text-align: left;
}

h1 {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #28262e;
  text-align: left;
  margin: 16px 0 24px 0;
}

h2 {
  font-family: PoppinsSemiBold;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #28262e;
  display: flex;
  margin-top: 48px;
  margin-bottom: 24px;
}

.surrounding {
  display: flex;
  height: 37px;
  margin-bottom: 12px;
  border-bottom: 1px solid #74737826;
}
</style>
