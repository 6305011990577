<template>
  <div class="tw-pb-24">
    <search-flights :resetLazyload="resetLazyload" />
    <sort-flights-component
      v-on:setDisplayFlights="setDisplayFlights($event)"
    />
    <div class="tw-flex tw-flex-col">
      <circle-loader :show="showCircleLoader"
        ><h2 class="tw-text-lg">
          Getting the best options based on your preferences...
        </h2></circle-loader
      >
      <template v-if="!!searchedFlights.length">
        <template
          v-for="(flight, index) in displayFlights.slice(
            0,
            1 + lazyLoadedFlights
          )"
        >
          <flights-item
            v-bind="flight"
            :key="`flight_${index}`"
            cartBehavior
            @add-to-cart="addFlightToCartHandler(flight, index)"
            @select-flight="addFlightToCartHandler(flight, index)"
            :inCart="inCart(flight)"
            :loading="loadingFlight(index)"
          />
        </template>
        <mugen-scroll :handler="fetchData" :should-handle="!loading">
          loading...
        </mugen-scroll>
      </template>
      <template v-else-if="!showCircleLoader">
        <h2 class="tw-text-center tw-text-lg">
          No available flights for your search criteria. Please change your
          criteria.
        </h2>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FlightsItem from './components/FlightsItem.vue';
import CircleLoader from '@/components/controls/containers/circleLoader/CircleLoader.vue';
import SearchFlights from './components/SearchFlights.vue';
import SortFlightsComponent from './components/SortFlightsComponent.vue';
import MugenScroll from 'vue-mugen-scroll';

export default {
  components: {
    FlightsItem,
    CircleLoader,
    SearchFlights,
    SortFlightsComponent,
    MugenScroll,
  },
  data: () => ({
    flight: null,
    loading: false,
    loadingFlightIndex: -1,
    showCircleLoader: true,

    mode: 'BEST',
    lazyLoadedFlights: 0,
  }),
  props: {},
  computed: {
    ...mapGetters('search', [ 'city', 'from', 'to' ]),
    ...mapGetters('flights', [ 'searchedFlights' ]),
    ...mapGetters([ 'experience', 'flightsInCart' ]),
    sortBestFlight() {
      return [];
    },
    sortCheapestFlight() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (flightA.price[0].price < flightB.price[0].price) {
          return -1;
        }
        if (flightA.price[0].price > flightB.price[0].price) {
          return 1;
        }
        return 0;
      });
    },
    sortFastestFlight() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (flightA.duration.total < flightB.duration.total) {
          return -1;
        }
        if (flightA.duration.total > flightB.duration.total) {
          return 1;
        }
        return 0;
      });
    },
    sortEarliestTakeOff() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (
          new Date(flightA.departure_at).getTime() <
          new Date(flightB.departure_at).getTime()
        ) {
          return -1;
        }
        if (
          new Date(flightA.departure_at).getTime() >
          new Date(flightB.departure_at).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    },
    sortLatestTakeOff() {
      const latestTakeoff = [ ...this.sortEarliestTakeOff ];
      latestTakeoff.reverse();
      return latestTakeoff;
    },
    sortEarliestLanding() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (
          new Date(flightA.arrival_at).getTime() <
          new Date(flightB.arrival_at).getTime()
        ) {
          return -1;
        }
        if (
          new Date(flightA.arrival_at).getTime() >
          new Date(flightB.arrival_at).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    },
    sortLatestLanding() {
      const latestLanding = [ ...this.sortEarliestLanding ];
      latestLanding.reverse();
      return latestLanding;
    },
    displayFlights() {
      if (this.mode === 'FASTEST') {
        return this.sortFastestFlight;
      }
      if (this.mode === 'CHEAPEST') {
        return this.sortCheapestFlight;
      }
      if (this.mode === 'Earliest take-off') {
        return this.sortEarliestTakeOff;
      }
      if (this.mode === 'Earliest landing') {
        return this.sortEarliestLanding;
      }
      if (this.mode === 'Latest take-off') {
        return this.sortLatestTakeOff;
      }
      if (this.mode === 'Latest landing') {
        return this.sortLatestLanding;
      }
      return this.searchedFlights;
    },
  },
  mounted() {},
  watch: {
    experience: {
      handler() {
        this.searchFlightsRequest();
      },
      immediate: true,
    },
    searchedFlights: {
      handler() {
        this.loading = false;
        this.showCircleLoader = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([ 'addFlightToCart' ]),
    ...mapActions('flights', [ 'searchFlights', 'setFlightRequest' ]),
    async addFlightToCartHandler(flight, index) {
      if (!this.inCart(flight)) {
        this.addFlightToCart({ flight });
        this.loadingFlightIndex = index;
        const res = await this.setFlightRequest(flight);
        this.loadingFlightIndex = -1;
        res && this.addFlightToCart({ flight });
      }
    },
    resetLazyload() {
      this.lazyLoadedFlights = 0;
      this.loading = false;
    },
    fetchData() {
      this.loading = true;
      if (this.displayFlights.length + 3 > this.lazyLoadedFlights) {
        this.lazyLoadedFlights += 3;
      }
      this.loading = false;
    },
    inCart(flight) {
      return this.flightsInCart.some(
        item => item.booking_token === flight.booking_token
      );
    },
    loadingFlight(index) {
      return (
        this.loadingFlightIndex !== -1 && index === this.loadingFlightIndex
      );
    },
    setDisplayFlights(criteria) {
      this.mode = criteria;
    },
  },
};
</script>
<style scoped>
.flights-container {
  padding: 0 90px;
  max-width: 1640px;
}
</style>
