<template>
  <div class="tw-flex tw-bg-gray-100 tw-border-gray-200 tw-border tw-rounded-xl tw-h-full tw-items-center">
    <slot></slot>
  </div>
</template>

<script>

export default {
};
</script>

<style lang="scss" scoped>
.search-flights-container {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}
</style>
