<template>
  <div
    class="
      search-flights-container
      tw-px-6 tw-rounded-2xl tw-border tw-border-gray-100
      mb-6 tw-py-2 tw-mt-2
    "
  >
    <div class="tw-mt-4 tw-flex">
      <div class="kiwiLabel">
        <KiwiIcon class="" />
        Getting the best prices powered by Kiwi.com
      </div>
      <v-tabs class="tabs tw-flex tw-flex-row tw-justify-between">
        <v-tab @click="$emit('setDisplayFlights', 'BEST')" class="label">
          <span class="labelColor">
            BEST
          </span>
          <span class="price">
            {{ bestFlight.price }}
          </span>
          <span class="duration">
            {{ bestFlight.duration }}
          </span>
        </v-tab>
        <sort-flights-divider />
        <v-tab @click="$emit('setDisplayFlights', 'CHEAPEST')" class="label">
          <span>
            CHEAPEST
          </span>

          <span class="price">
            {{ cheapestFlight.price }}
          </span>
          <span class="duration">
            {{ cheapestFlight.duration }}
          </span>
        </v-tab>
        <sort-flights-divider />
        <v-tab @click="$emit('setDisplayFlights', 'FASTEST')" class="label">
          <span>FASTEST</span>

          <span class="price">
            {{ fastestFlight.price }}
          </span>
          <span class="duration">
            {{ fastestFlight.duration }}
          </span>
        </v-tab>
      </v-tabs>
      <div
        class="tw-flex tw-flex-row"
        style="margin-top: -25.3px;"
      >
        <sort-flights-divider class="tw-mr-4" />
        <div class="tw-flex tw-flex-row tw-w-full tw-items-center">
          <v-combobox
            v-model="sortValue"
            @change="$emit('setDisplayFlights', `${sortValue}`)"
            :items="sortOptions"
            class="tw-flex buttonLabel tw-w-full"
            prepend-icon="mdi-filter-variant"
            placeholder="OTHER SORT"
            style="padding-top: 0; margin-top: 0;"
          >
            <!-- <template v-slot:prepend> <DropdownIcon></DropdownIcon></template> -->

            OTHER SORT
          </v-combobox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SortFlightsDivider from './SortFlightsDivider.vue';
import KiwiIcon from '@/assets/inspirationPage/tabicons/kiwi.svg';
//import DropdownIcon from '@/assets/inspirationPage/dropdown.svg';
export default {
  components: {
    SortFlightsDivider,
    KiwiIcon
    //DropdownIcon,
  },
  data: () => ({
    sortValue: '',
    sortOptions: [
      'Earliest take-off',
      'Latest take-off',
      'Earliest landing',
      'Latest landing'
    ]
  }),
  computed: {
    ...mapGetters('flights', [ 'searchedFlights' ]),
    cheapestFlight() {
      const cheapestPrice = Math.min(
        ...this.searchedFlights.map(flight => flight.price[0].price)
      );
      const duration = this.searchedFlights.find(
        flight => flight.price[0].price === cheapestPrice
      ).duration.total;
      return {
        duration: `${Math.floor(duration / 3600)}h ${(duration % 3600) / 60}m`,
        price: `€${cheapestPrice}`
      };
    },
    fastestFlight() {
      const fastestDuration = Math.min(
        ...this.searchedFlights.map(flight => flight.duration.total)
      );
      const price = this.searchedFlights.find(
        flight => flight.duration.total === fastestDuration
      ).price[0].price;
      return {
        duration: `${Math.floor(fastestDuration / 3600)}h ${(fastestDuration %
          3600) /
          60}m`,
        price: `€${price}`
      };
    },
    bestFlight() {
      // const bestFlight = [ ...this.sortCheapestFlightTemp ].length / 2;
      // const bestPrice = Math.min( ...bestFlight.map(flight => flight.price[0].price));
      // console.log(bestPrice);
      // const bestDuration = bestFlight.duration;
      // return {
      //   duration: `${Math.floor(bestDuration / 3600)}h ${(bestDuration % 3600) / 60}m`,
      //   price: `€${bestPrice}`
      // };
      //return { duration: '0h 0m', price: '€0' };
      const cheapestPrice = Math.min(
        ...this.searchedFlights.map(flight => flight.price[0].price)
      );
      const duration = this.searchedFlights.find(
        flight => flight.price[0].price === cheapestPrice
      ).duration.total;
      return {
        duration: `${Math.floor(duration / 3600)}h ${(duration % 3600) / 60}m`,
        price: `€${cheapestPrice}`
      };
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.v-tab {
  min-width: 15vw !important;
}
.search-flights-container {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.search-flights-container,
.tabs {
  max-height: 115px;
  height: 115px;
}

.button {
  background-color: unset !important;
}

.dropdown {
  height: 10px;
}

.buttonLabel {
  font-family: 'PoppinsSemiBold';
  font-size: 1rem;
  color: #28262e;
}

.label {
  font-family: 'PoppinsSemiBold';
  font-size: 0.875rem;
  color: #747378 !important;
  letter-spacing: 0.1rem;
  display: flex;
  flex-direction: column;
}

.price {
  margin-top: 0.75rem;
  font-size: 1.5rem;
  color: #28262e !important;
  font-family: 'MontserratSemiBold' !important;
  letter-spacing: unset !important;
}

.duration {
  font-family: 'MontserratMedium' !important;
  font-size: 1rem !important;
  letter-spacing: unset !important;
}

.buttonLabel::v-deep .v-input__append-inner {
  display: none;
}

.kiwiLabel {
  font-family: 'MontserratRegular';
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.01rem;
  height: 86.49px;
  max-height: 86.49px;
  width: 152.75px;
  min-width: 152.75px;
}
</style>
