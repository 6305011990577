<template>
  <v-menu
  v-model="menu"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  min-width="auto"
  >
  <template v-slot:activator="{ on }">
    <div class="tw-flex tw-items-center tw-w-full flights-date-picker-input"
    :class="{ 'disabled': disabled }"
    ><calendar-icon class="tw-w-6 tw-h-6 tw-ml-2 tw-text-primary" />
      <div>
      <div class="label tw-text-left tw-text-gray-main tw-pl-3 tw-uppercase tw-text-xs tw-font-semibold datePadding">{{label}}</div>
        <v-text-field
          class="text-field right"
          :value="formattedValue"
          dense
          flat
          solo
          hide-details
          readonly
          v-on="!disabled && on"
        >
        </v-text-field>
      </div>
    </div>
  </template>
  <v-date-picker
    v-model="date"
    :min="min"
    @input="menu = false"
    no-title
  ></v-date-picker>
</v-menu>
</template>
<script>
import CalendarIcon from '@/assets/bookingPage/flights/calendar.svg';
import moment from 'moment';

export default {
  props: {
    label: String,
    value: String, 
    min: String,
    disabled: Boolean,
  },
  data: () => ({
    menu: false,
  }),
  components:{
    CalendarIcon
  },
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
      },
    },
    formattedValue: function() {
      return moment(this.value).format('DD/MM/YY');
    },
  },
};
</script>

<style lang="scss">
.flights-date-picker-input.disabled{
  color: #ccc !important;
  svg,
  .tw-text-gray-main,
  input {
    color: #ccc;
  }
}

.flights-date-picker-input {
   font-family: 'MontserratSemiBold';
}

.datePadding {
 padding-top: 0.3rem;
 margin-bottom: -0.4rem;
}
</style>
