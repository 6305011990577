<template>
  <div class="tw-p-1 md:tw-p-10">
    <v-form v-model="isValid" class="tw-mt-18">
      <div class=" tw-text-5xl tw-font-bold tw-my-10 comingsoon">
        Coming Soon! :)
      </div>
    </v-form>
  </div>
  
</template>

<script>
export default {
  data: () => ({
    isValid: true,
  }),
};
</script>
<style scoped>
  .scroll-height {
    max-height: 68vh;
  }
  .scroll-height::-webkit-scrollbar-track-piece  {
    background-color: white;
  }
  .v-form {
    border: none;
    padding: 0 40px 40px 40px;
    box-shadow: none;
  }

  .comingsoon {
      font-family: 'RedHatDisplayBold';
  }
</style>
