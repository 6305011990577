<template>
  <div
    class="section bg-image hidden-xs-only"
    :style="backgroundImage"
  >
    <div class="bg-shade">
    <div class="tw-flex tw-flex-col tw-justify-center tw-min-h-screen tw-pl-32">
        <breadcrumbs :city="city" class="breadcrumbs tw-z-10 tw-py-0 "/>
        <div class="tw-flex tw-flex-row tw-flex-grow tw-flex-wrap">
          <div class="tw-w-1/2 tw-flex tw-mt-20">
            <div class="city-container">
                <h2 class="city-header">{{ city.name }}</h2>
                <div class="read-more-scroller">
                  <p class="readmore-content tw-leading-6 tw-inline">
                    {{
                      readMore
                        ? city.description
                        : city.description.slice(0, 250) + '...'
                    }}
                  </p>
                  <a v-if="!readMore" class="readMore" @click.prevent="toggleReadmore()">See more</a> 
                </div>
            </div>
          </div>

          <div class="tw-w-1/2 tw-items-center">
            <div class="inspiration-container">
              <InspirationSlider
                :experiences="city.experiences"
                :city="city"
                @experience-selected="experienceSelectionHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InspirationSlider from '@/views/CityPage/InspirationCitySlider';
import Breadcrumbs from '@/views/layouts/common/Breadcrumbs.vue';
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';

export default {
  name: 'DiscoverCity',
  data() {
    return {
      selectedCity: this.$route.query.city,
      readMore: false,
    };
  },
  props: [ 'city' ],
  computed: {
    backgroundImage() {
      return {
        backgroundImage:
          'url(' + transformImage(this.city.background_image) + ')'
      };
    },
  },
  methods: {
    ...mapMutations([ 'setDestinationBackground' ]),
    toggleReadmore() {
      this.readMore = true;
      this.$emit('readmore-toggled', this.readMore);
    },
    experienceSelectionHandler() {
      this.setDestinationBackground(this.city.background_image);
    },
    getInspirationLink(experience) {
      return {
        title: 'Discover Trip',
        name: 'InspirationPage',
        params: {
          inspirationId: experience.exp_id,
        }
      };
    },
  },
  components: {
    InspirationSlider,
    Breadcrumbs
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.bg-image {
  background-position: center;
  background-size: cover;
  height: 90vh;
  
  &:not(:first-child) {
    margin-top: -10vh;
  }
}

.bg-shade {
  background: linear-gradient(90deg, #000000 -9.62%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
}

.breadcrumbs {
  margin-top: 17vh;
  margin-left: -2vw;
}

.read-more-scroller {
  max-height: 20vh;
  @include respond-to(xl) {
    max-height: 30vh;
    }
  overflow: overlay;
  margin-left: 1rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255);
}

::-webkit-scrollbar-track {
  background: rgba(225, 255, 255, 0.2);
}
.readMore {
  //float: right;
  color: white;
  font-size: 1.125rem;
  font-family: 'MontserratBold';
  @include respond-to(lg) {
    font-size: 1.3rem;
  }
  @include respond-to(md) {
    font-size: 1.1rem;
  }
  @include respond-to(sm) {
    font-size: 1.4rem;
  }
  @include respond-to(xs) {
    font-size: 15px;
  }
}
.readMore:hover {
  background-color: transparent;
}

.city-container {
  padding-bottom: 10px;
  margin: 0 7%;

  @include respond-to(md-xl) {
    //background: rgba(0, 0, 0, 0.37);
    padding-right: 30px;
    margin-left: -100vh;
    padding-left: 100vh;
    padding-bottom: 36px;
  }
}

.city-header {
  font-family: PoppinsSemiBold;
  letter-spacing: -0.4rem;
  line-height: 0.9;
  font-size: 7.25vw;
  padding: 0 0 1.5rem 0;
  color: white;

  @include respond-to(xl) {
    font-size: 6rem;
  }
}


.readmore-content {
  font-size: 1rem;
  color: white;
  font-family: 'MontserratMedium';
  opacity: 0.9 !important;
  @include respond-to(md) {
    font-size: 1.5rem;
    opacity: 0.8 !important;
  }
}

.inspiration-container {
  padding-bottom: 8rem;
}

.inspiration-slider {
 margin-top: -20vh;
}

.inspiration-slider::v-deep .background-card,
.inspiration-slider::v-deep .card {
  width: 18vw;
  height: 28vw;
  border-radius: 30px !important;
  overflow: hidden;
}
.inspiration-slider::v-deep .splide__slide {
  margin-right: 2% !important;
}
.inspiration-slider::v-deep .splide__track {
  overflow: hidden;
}
.inspiration-slider::v-deep .splide {
  padding: 10em 4em 0 4em !important;
}
.inspiration-slider::v-deep .splide__arrow {
  margin-top: 4em !important;
}
.inspiration-slider::v-deep .slide-wrapper  {
  margin-left: -25% !important;
}
.inspiration-card-single::v-deep .insta-story,
.inspiration-slider::v-deep .insta-story {
  position: static !important;
  top: unset !important;
  left: unset !important;
  padding-bottom: 0;
}
.inspiration-slider::v-deep .insta-story {
  height: 28vw !important;
}


.booking-container {
  z-index: 20;
  background-color: rgba(151, 151, 151, 0.8);
  border-radius: 57px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 0 12px;
  margin: 10px auto 50px auto;

  @include respond-to(xs-sm) {
    margin: 10px auto;
  }

  .icon {
    width: 24px;
    height: 24px;
    background-position: center center;
    background-size: cover;
    margin: 16px 12px;
  }
}
</style>

