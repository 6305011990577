<template>
  <div class="offer-list-wrapper tw-px-12 hidden-xs-only">
    <div>
      <div v-if="activeTab === 'flights'">
        <flights-tab-item ref="flightsTabItem" :experience="experience" />
      </div>
      <div v-if="activeTab === 'activities'">
        <offers-tab-item
          type="activities"
          :experience="experience"
          :offers="activities"
          empty-field-text="Activities not found..."
        />
      </div>
      <div v-if="activeTab === 'accommodation'">
        <accommodation-tab-item :city="city" :setActiveTab="setActiveTab"  ref="accommodations"/>
      </div>

      <div v-if="activeTab === 'restaurants'">
        <offers-tab-item
          type="restaurants"
          :experience="experience"
          :offers="restaurants"
          key="restaurants"
          emptyFieldText="Restaurants not found..."
        />
      </div>

      <div v-if="activeTab === 'events'">
        <event-tab-item></event-tab-item>
      </div>
    </div>
  </div>
</template>
<script>
import FlightsTabItem from './tab-items/flights/FlightsTabItem.vue';
import OffersTabItem from './tab-items/OffersTabItem.vue';
import accommodationTabItem from './tab-items/AccommodationTabItem.vue';
import EventTabItem from './tab-items/EventsTabItem.vue';

export default {
  components: {
    OffersTabItem,
    FlightsTabItem,
    accommodationTabItem,
    EventTabItem,
  },
  props: {
    activeTab: String,
    experience: Object,
    city: Object,
    setActiveTab: Function,
  },
  data: function() {
    return {
      offers: [],
    };
  },

  computed: {
    activities() {
      return this.experience.activities;
    },
    restaurants() {
      return this.experience.restaurants;
    },
    hotels() {
      return this.experience.hotels;
    },
  },
};
</script>

<style scoped>
.offer-list-wrapper {
  position: relative;
  margin: auto;
  overflow: hidden;
}
</style>
