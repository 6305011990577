var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer"},[_c('h1',[_vm._v("Deals")]),_c('div',{staticClass:"rooms-container"},[_c('rooms-list',{attrs:{"hideOptions":true,"page":_vm.page,"hotel":_vm.hotel},on:{"close":() => {
          _vm.$emit('close');
        },"addToCard":(hotel) => {
          _vm.$emit('addToCard', hotel);
        }}})],1),_c('div',{staticClass:"showing"},[_c('div',[_vm._v(" Showing "+_vm._s(_vm.page * 30 - 29)+" "),(_vm.page * 30 < _vm.dealsCount)?[_vm._v("- "+_vm._s(_vm.page * 30))]:(_vm.dealsCount > 1)?[_vm._v("- "+_vm._s(_vm.dealsCount))]:_vm._e(),_vm._v(" of "+_vm._s(_vm.dealsCount)+" deals ")],2),_c('div',{staticClass:"tw-flex tw-ml-auto tw-gap-3 tw-select-none"},[_c('div',{staticClass:"tw-cursor-pointer arrow tw-bg-paragraph tw-bg-opacity-10 tw-rounded-full tw-flex tw-items-center tw-justify-center hover:tw-bg-opacity-5",on:{"click":() => {
            if (_vm.page > 1) _vm.page--;
          }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-chevron-left ")])],1),_vm._l((_vm.pagesCount),function(index){return _c('div',{key:index,staticClass:"page-number",class:{ activePage: index === _vm.page },on:{"click":() => {
            _vm.selectedImageIndex = null;
            _vm.page = index;
          }}},[_vm._v(" "+_vm._s(index)+" ")])}),_c('div',{staticClass:"arrow tw-cursor-pointer tw-bg-paragraph tw-bg-opacity-10 tw-rounded-full tw-flex tw-items-center tw-justify-center hover:tw-bg-opacity-5",on:{"click":() => {
            if (_vm.pagesCount > _vm.page) _vm.page++;
          }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-chevron-right ")])],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }