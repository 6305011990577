<template>
  <div class="tw-mx-5">
    <div
      v-if="!hideOptions"
      class="tw-text-left tw-font-montserrat-semi-bold tw-text-paragraph tw-text-sm"
    >
      SEE DEALS FOR
      <div
        class="tw-font-montserrat-medium tw-mt-4 tw-mb-8 tw-flex tw-flex-row tw-text-title tw-text-base"
      >
        <div class="tw-flex">
          <div class="round tw-mr-3">
            <input
              v-model="cancellationFilter"
              type="checkbox"
              id="checkbox"
              class="tw-h-5 tw-w-5"
            />
            <label for="checkbox"></label>
          </div>
          <div class="tw-mt-1">
            Free Cancellation
          </div>
        </div>
        <div class="tw-ml-6 tw-flex">
          <span class="round tw-mr-3">
            <input
              v-model="breakfastFilter"
              type="checkbox"
              id="checkbox2"
              class="tw-h-5 tw-w-5"
            />
            <label for="checkbox2"></label>
          </span>
          <div class="tw-mt-1">
            Free Breakfast
          </div>
        </div>
      </div>
    </div>

    <div v-for="(deal, index) in (page ? deals.slice(page * 30 - 30, page * 30) :deals)" :key="deal.room.name + index">
      <div v-if="index === 0" class="header">
        <div>Type of Stay</div>
        <div>Sleeps</div>
        <div>Provider</div>
        <div>Seat Left</div>
        <div>Total Price</div>
      </div>

      <template
        v-if="
          (new Date(deal.item.cancellationPolicies[0].from).getDate() >=
            new Date().getDate() ||
            !cancellationFilter) &&
            (deal.item.boardCode === 'BB' || !breakfastFilter)
        "
      >
        <div
          class="room-item tw-flex tw-items-center tw-justify-between tw-mb-3"
        >
          <div class="type-of-stay">
            <div>{{ deal.room.name }}</div>
            <div class="cancellation">
              {{
                new Date(deal.item.cancellationPolicies[0].from).getDate() >=
                new Date().getDate()
                  ? 'Free cancellation'
                  : 'No refund available'
              }}

              <template v-if="deal.item.boardCode === 'BB'">
                <div class="circle"></div>
                Free breakfast
              </template>
            </div>
          </div>
          <div class="tw-flex">
            <User1 v-if="deal.item.adults === 1" />
            <User2 v-else-if="deal.item.adults === 2" />
            <template v-else>
              <User1 />
              <div class="multiplier">x{{ deal.item.adults }}</div></template
            >
          </div>
          <div class="tw-flex">
            <img :src="hotelbedsLogoURL" />
          </div>
          <div class="tw-flex allotment">{{ deal.item.allotment }} Left</div>
          <div class="tw-flex">
            <div class="price">
              <div>{{ setPrice(deal.item) }}</div>
              <div v-if="deal.item.sellingRate">(inc. taxes and fees)</div>
            </div>
            <v-btn class="add-room-btn tw-ml-auto" @click="addToCart(deal.item)"
              ><CartIcon class="tw-inline-block tw-h-4 tw-w-4 tw-mr-3" />Add to
              cart</v-btn
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { convertDate } from '@/utils/appDate.js';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import HotelbedsLogo from '@/assets/hotelDetails/hotelbeds-logo.png';
import User1 from '@/assets/hotelDetails/user-1.svg';
import User2 from '@/assets/hotelDetails/user-2.svg';
// import User3 from '@/assets/hotelDetails/user-3.svg';

export default {
  props: [ 'hotel', 'hideOptions', 'page' ],
  data: () => ({
    selectedReview: null,
    open: false,
    cancellationFilter: false,
    breakfastFilter: false
  }),
  components: {
    CartIcon,
    User1,
    User2
    // User3
  },
  methods: {
    changeDate(date) {
      return convertDate(date);
    },
    addToCart: function(item) {
      const hotel = {
        ...this.hotel,
        rateKey: item.rateKey,
        sellingRate: item.sellingRate,
        net: item.net,
        rateType: item.rateType
      };
      this.$emit('addToCard', hotel);
      this.$emit('close');
    },
    setSelectedReview(data) {
      this.selectedReview = data;
      this.open = true;
    },
    handleClose() {
      this.selectedReview = null;
      this.open = false;
    },
    setPrice(item) {
      return '€' + (item.sellingRate ? item.sellingRate : item.net);
    }
  },
  computed: {
    hotelbedsLogoURL: () => {
      return HotelbedsLogo;
    },
    isCancellationPolicy: function() {
      return new Date(this.hotel.cancellationPolicy) > new Date();
    },
    deals: {
      get() {
        let result = [];
        this.hotel.rooms.forEach(room => {
          room.rates.forEach(item => {
            result.push({
              room: room,
              item: item
            });
          });
        });
        return result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-room-btn {
  height: 38px !important;
  background: #ffe4ec !important;
  color: #d62a53 !important;
  border-radius: 90px !important;
  box-shadow: none !important;
  font-family: MontserratSemiBold !important;
  font-size: 12px !important;
  letter-spacing: -0.02em !important;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #747378;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
  margin-top: 7px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.allotment {
  font-family: MontserratSemiBold;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #ff2c1f;
}
.round input[type='checkbox']:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.header {
  padding-bottom: 13px;
}
.room-item {
  height: 70px;
}

.header {
  font-family: MontserratSemiBold;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #747378;
}

.header,
.room-item {
  border-bottom: 1px solid #74737826;
  div {
    text-align: left;
    display: inline-flex;
    align-items: center;
  }
  > div:nth-child(1) {
    width: 30.5%;
  }
  > div:nth-child(2) {
    width: 12.22%;
  }
  > div:nth-child(3) {
    width: 13.44%;
  }
  > div:nth-child(4) {
    width: 13.88%;
  }
  > div:nth-child(5) {
    width: 29.96%;
  }
}

.multiplier {
  font-family: MontserratsemiBold;
  font-size: 12px;
  text-align: center;
  color: #28262e;
}

.price {
  display: block !important;

  > div:nth-child(1) {
    font-family: MontserratSemiBold;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #28262e;
    padding-bottom: 3px;
  }
  > div:nth-child(2) {
    font-family: MontserratMedium;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #747378;
    padding-top: 3px;
  }
}

.type-of-stay {
  display: block !important;

  > div:nth-child(1) {
    font-family: MontserratSemiBold;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #28262e;
    margin-bottom: 6px;
    width: 100%;
  }
  > div:nth-child(2) {
    font-family: MontserratSemiBold;
    font-size: 12px;
    color: #747378;
    padding-top: 6px;
  }
}

.circle {
  background: #d5d5d7;
  width: 6px;
  height: 6px;
  border-radius: 99%;

  margin-left: 6px;
  margin-right: 6px;
}
</style>
