<template>
  <div class="container">
    <div
      class="
        tw-font-poppins
        tw-font-semibold
        tw-max-w-sm
        tw-text-3xl
        tw-font-bold
        tw-my-12
        tw-m-auto
        tw-text-center
      "
    >
      Enter Details to Check Availability
    </div>
    <v-form class="tw-flex tw-justify-between" v-model="isValid">
      <div class="user-input-container tw-flex-grow">
        <div
          class="
            accommodation-location-selector
            tw-flex tw-items-center tw-w-full
          "
        >
          <!-- <search-icon class="tw-w-6 tw-h-6 tw-ml-2" /> -->
          <div class="forSafari">
            <div
              class="
                tw-text-xs
                tw-font-montserrat-semi-bold
                tw-text-paragraph
                tw-mt-7
                tw-ml-4
                tw-text-left
                title-text
              "
            >
              LOCATION
            </div>
            <text-field
              :rules="textRules"
              v-model="destination"
              :disabled="true"
              :label="destination"
            >
              {{ destination }}
            </text-field>
          </div>
        </div>
      </div>
      <div class="user-input-container">
        <div>
          <DatePicker
            v-model="checkInDate"
            :min="minCheckIn"
            label="CHECK-IN"
            :rules="[dateFromRules.compareDates(checkInDate, checkOutDate)]"
          />
        </div>
        <div class="divider"></div>
        <div>
          <DatePicker
            v-model="checkOutDate"
            :min="checkInDate"
            label="CHECK-OUT"
            :rules="dateCheckOutRules"
          />
        </div>
      </div>
      <div class="user-input-container guests-container">
        <span class="guest-select tw-flex">
          <!-- <div class="user-input-container"> -->
          <div class="input-title">Guests</div>
          <div
            @click="showGuestDropdown = !showGuestDropdown"
            class="room-guest-count tw-flex"
          >
            {{ roomsCount }}
            room<template v-if="roomsCount !== 1">s</template>,

            {{ adultsCount + childrenCount }}
            guest<template v-if="adultsCount + childrenCount !== 1">s</template>
          </div>
          <transition name="fade" appear>
            <div v-if="showGuestDropdown" class="guest-dropdown">
              <ul>
                <li>
                  <div>Rooms</div>
                  <div
                    class="decrease"
                    @click="
                      () => {
                        roomsCountToApply -= 1;
                      }
                    "
                    v-bind:style="{
                      pointerEvents: roomsCountToApply < 2 ? 'none' : 'unset',
                      filter: roomsCountToApply < 2 ? 'grayscale(100%)' : '',
                    }"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="count">{{ roomsCountToApply }}</div>
                  <div
                    class="add"
                    v-bind:style="{
                      pointerEvents: roomsCountToApply >= 8 ? 'none' : 'unset',
                      filter: roomsCountToApply >= 8 ? 'grayscale(100%)' : '',
                    }"
                    @click="
                      () => {
                        roomsCountToApply += 1;
                      }
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 2.91699V11.0837"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <div>Adults</div>
                  <div
                    class="decrease"
                    @click="
                      () => {
                        adultsCountToApply -= 1;
                      }
                    "
                    v-bind:style="{
                      pointerEvents: adultsCountToApply < 2 ? 'none' : 'unset',
                      filter: adultsCountToApply < 2 ? 'grayscale(100%)' : '',
                    }"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="count">{{ adultsCountToApply }}</div>
                  <div
                    class="add"
                    v-bind:style="{
                      pointerEvents: adultsCountToApply >= 8 ? 'none' : 'unset',
                      filter: adultsCountToApply >= 8 ? 'grayscale(100%)' : '',
                    }"
                    @click="
                      () => {
                        adultsCountToApply += 1;
                      }
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 2.91699V11.0837"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <div>Children <label>Ages 0-17</label></div>
                  <div
                    class="decrease"
                    @click="
                      () => {
                        childrenCountToApply -= 1;
                      }
                    "
                    v-bind:style="{
                      pointerEvents:
                        childrenCountToApply < 1 ? 'none' : 'unset',
                      filter: childrenCountToApply < 1 ? 'grayscale(100%)' : '',
                    }"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="count">{{ childrenCountToApply }}</div>
                  <div
                    class="add"
                    v-bind:style="{
                      pointerEvents:
                        childrenCountToApply >= 4 ? 'none' : 'unset',
                      filter:
                        childrenCountToApply >= 4 ? 'grayscale(100%)' : '',
                    }"
                    @click="
                      () => {
                        childrenCountToApply += 1;
                      }
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 2.91699V11.0837"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.91797 7H11.0846"
                        stroke="#D62A53"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </li>
              </ul>
              <v-btn
                color="primary"
                class="proceed-btn"
                @click="
                  () => {
                    roomsCount = roomsCountToApply;
                    adultsCount = adultsCountToApply;
                    childrenCount = childrenCountToApply;
                    showGuestDropdown = false;
                    $nextTick(() => {
                      if (!isaccommodationSearching) findHotels();
                    });
                  }
                "
              >
                Apply
              </v-btn>
            </div>
          </transition>
        </span>
        <!-- </div> -->
        <!-- <div>
          <Select
            :values="roomsCounts"
            :value="roomsCount"
            @update="roomsCount = $event"
            label="ROOMS"
          />
        </div> -->
        <!-- <div class="divider"></div> -->
        <!-- <div>
          <Select
            :values="adultsCounts"
            :value="adultsCount"
            @update="adultsCount = $event"
            label="ADULTS"
          />
        </div> -->
        <!-- <div class="divider"></div> -->
        <!-- <div>
          <Select
            :values="childrenCounts"
            :value="childrenCount"
            @update="childrenCount = $event"
            label="CHILDREN"
          />
        </div> -->
      </div>

      <FlatButton
        @click="findHotels"
        :disabled="!isValid || isLoading"
        :loading="isLoading"
      >
        <template #icon>
          <CalendarIcon />
        </template>
        Check Availability
      </FlatButton>
    </v-form>
    <div
      class="v-form tw-flex tw-justify-between"
      v-if="childrenAges.length > 0"
    >
      <div class="user-input-container max-width-none">
        <div
          class="tw-contents"
          v-for="(item, index) in childrenAges"
          :key="item + index"
        >
          <div class="divider" v-if="index > 0"></div>

          <select-age
            :values="childAge"
            :value="paxes[index].age"
            @update="
              ($event => {
                paxes[index].age = $event;
                if (!isaccommodationSearching) findHotels();
              })($event)
            "
            :rules="ageRules"
            :label="`AGE CHILD ${index + 1}`"
          />
        </div>
      </div>
    </div>
    <div class="tw-mb-10 tw-text-center" v-if="isNoContent">
      Sorry there are no available hotels for your search criteria
    </div>

    <div
      v-if="!isaccommodationSearching"
      class="
        tw-max-w-max tw-m-auto tw-grid tw-grid-cols-1
        md:tw-grid-cols-2
        xl:tw-grid-cols-3
        tw-gap-4
        md:tw-gap-8
        tw-pb-12
      "
      @scroll="handleScroll"
    >
      <hotel-item
        v-for="hotel in batchHotels(batchCount)"
        :key="hotel.rateKey"
        :hotel="hotel"
        :nightsCount="nightsCount"
        @addToCard="addToCard"
        @click="handleHotelCardClick"
      />
    </div>

    <hotel-details
      v-if="!!selectedHotel"
      @close="
        () => {
          modalShowing = false;
        }
      "
      :modalShowing="modalShowing"
      @change="modalShowing = $event"
      :hotel="selectedHotel"
      @addToCard="addToCard"
      :checkin="checkInDate"
      :checkout="checkOutDate"
      :destination="destination"
      :childrenCount="childrenCount"
      :roomsCount="roomsCount"
      :adultsCount="adultsCount"
    >
      <ModifySearch
        :checkInDateInitial="checkInDate"
        :minCheckInInitial="minCheckIn"
        :dateFromRulesInitial="dateFromRules"
        :checkOutDateInitial="checkOutDate"
        :dateCheckOutRulesInitial="dateCheckOutRules"
        :roomsCountsInitial="roomsCounts"
        :childrenCountsInitial="childrenCounts"
        :modifySearchInitial="modifySearch"
        :adultsCountsInitial="adultsCounts"
        :isValidInitial="isValid"
        :modifyLoading="modifyLoading"
        :childrenAgesInitial="childrenAges"
        :roomsCountInitial="roomsCount"
        :adultsCountInitial="adultsCount"
        :childrenCountInitial="childrenCount"
        :paxesInitial="paxes"
        :childAgeInitial="childAge"
        :ageRulesInitial="ageRules"
        :nightsCountInitial="nightsCount"
        :findHotels="findHotels"
        ref="modifiedData"
      />
    </hotel-details>

    <transition name="fade" appear>
      <div
        class="modal-overlay"
        v-show="showingInfo"
        @click="showingInfo = false"
      ></div>
    </transition>
    <div class="modal" v-if="showingInfo">
      <AddHotelModal @close="showingInfo = false" class="modal" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FlatButton from '../FlatButton';
import { reduceHotelseResponse } from '@/utils/appHotels';
import CalendarIcon from '@/assets/inspirationPage/calendar.svg';
import DatePicker from './components/DatePicker.vue';
// import Select from './components/Select.vue';
import { availabilityApi } from '@/api/traveloHotels';
import AddHotelModal from './components/AddHotelModal.vue';
import {
  dateForCalendar,
  diffDays,
  convertToDatePickerFormat,
} from '@/utils/appDate.js';
import HotelItem from './components/HotelItem.vue';
import HotelDetails from './components/HotelDetails.vue';
import SelectAge from './components/SelectAge.vue';
// import SearchIcon from '@/assets/inspirationPage/search.svg';
import TextField from './components/TextField.vue';
// import hotelCountryCode from '@/assets/json/hotelCountryCode.json';
import ModifySearch from './ModifySearch.vue';

export default {
  data: () => ({
    isValid: true,
    showGuestDropdown: false,
    userDestination: '',
    modifyLoading: false,
    checkInDate: '',
    checkOutDate: '',
    minCheckIn: '',
    dateFromRules: {
      compareDates(checkInDate, checkOutDate) {
        this.isValid = new Date(checkInDate) <= new Date(checkOutDate);
        return (
          new Date(checkInDate) <= new Date(checkOutDate) ||
          'Date check-in must be less than date check-out'
        );
      },
    },
    textRules: [ v => !!v || 'Field is required' ],
    ageRules: [ v => parseInt(v) > 0 || 'Is required' ],
    dateCheckOutRules: [ v => !!v || 'Check-out date is required' ],
    nightsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    roomsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    adultsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    childrenCounts: [ 0, 1, 2, 3, 4 ],
    childAge: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ],
    nightsCount: 1,
    roomsCount: 1,
    adultsCount: 1,
    childrenCount: 0,
    batchCount: 2,
    modalShowing: false,
    selectedHotel: null,
    showingInfo: false,
    roomsCountToApply: 1,
    adultsCountToApply: 1,
    childrenCountToApply: 0,
  }),
  beforeDestroy() {
    document.querySelector('html')?.classList.remove('hotel-modal-open');
  },
  watch: {
    modalShowing: function() {
      if (this.modalShowing) {
        document.querySelector('html')?.classList.add('hotel-modal-open');
      } else {
        document.querySelector('html')?.classList.remove('hotel-modal-open');
      }
    },
    nightsCount: function(newValue) {
      this.$store.commit('setIsNoContent', false);
      this.nightsCounts =
        newValue < 10
          ? new Array(10).fill(1).map((it, i) => i + 1)
          : new Array(newValue + 1).fill(1).map((it, i) => i + 1);
      if (!this.isaccommodationSearching) this.findHotels();
    },
    checkInDate: function(newValue) {
      this.$store.commit('setIsNoContent', false);
      const dif = diffDays(this.checkOutDate, newValue);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(newValue) <= new Date(this.checkOutDate);
      if (!this.isaccommodationSearching) this.findHotels();
    },
    checkOutDate: function(newValue) {
      this.$store.commit('setIsNoContent', false);
      const dif = diffDays(newValue, this.checkInDate);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(this.checkInDate) <= new Date(newValue);
      if (!this.isaccommodationSearching) this.findHotels();
    },
    roomsCount: function() {
      this.$store.commit('setIsNoContent', false);
    },
    adultsCount: function() {
      this.$store.commit('setIsNoContent', false);
    },
    childrenCount: function() {
      this.$store.commit('setIsNoContent', false);
    },
  },
  components: {
    FlatButton,
    CalendarIcon,
    DatePicker,
    // Select,
    // ResultSearchTopButton,
    HotelItem,
    HotelDetails,
    SelectAge,
    AddHotelModal,
    // SearchIcon,
    // 
    ModifySearch,
    TextField,
  },
  props: [ 'city', 'setActiveTab' ],
  computed: {
    ...mapState({
      hotels: state => state.experiences.availabilityHotels,
      isLoading: state => state.experiences.isLoading,
      startDate: state => state.search.from,
      endDate: state => state.search.to,
      isNoContent: state => state.experiences.isNoContent,
    }),
    ...mapGetters([
      'cityaccommodation',
      'isaccommodationSearching',
      'batchHotels',
      'accommodationsInCart',
    ]),
    destination: {
      set: function(val) {
        this.userDestination = val;
      },
      get: function() {
        return this.cityaccommodation;
      },
    },
    childrenAges: function() {
      return new Array(this.childrenCount).fill('');
    },
    paxes: function() {
      return new Array(this.childrenCount)
        .fill('')
        .map(() => ({ type: 'CH', age: 0 }));
    },
  },
  mounted() {
    this.$store.commit('setIsLoading', true);
    this.$store.commit('setIsNoContent', false);

    if (this.startDate && this.endDate) {
      this.checkInDate = convertToDatePickerFormat(this.startDate);
      this.checkOutDate = convertToDatePickerFormat(this.endDate);
    } else {
      const day = 60 * 60 * 24 * 1000 * 30;
      const dayOut = 60 * 60 * 24 * 1000 * 3;
      this.checkInDate = dateForCalendar(new Date(new Date().getTime() + day));
      this.checkOutDate = dateForCalendar(
        new Date(new Date(this.checkInDate).getTime() + dayOut)
      );
    }

    document.addEventListener('click', event => {
      if (!document.querySelector('.guest-select').contains(event.target)) {
        this.showGuestDropdown = false;
      }
    });
  },
  async created() {
    if (!this.cityaccommodation) {
      await this.loadExperiences('1-2-3-4-5-6-7-8-9');
    }
  },
  methods: {
    async findHotels() {
      if (this.modalShowing) {
        return this.modifySearch();
      }
      this.$store.commit('setIsNoContent', false);
      this.loadAvailabilityHotels({
        geolocation: {
          latitude: this.city.coordinates_lat,
          longitude: this.city.coordinates_lng,
          radius: 50,
          unit: 'km',
        },
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        nightsCount: this.nightsCount,
        roomsCount: this.roomsCount,
        adultsCount: this.adultsCount,
        childrenCount: this.childrenCount,
        paxes: this.paxes,
      });
    },
    async loadModifySearchHotels(data) {
      try {
        // const country = hotelCountryCode.find(
        //   it => it.name === this.cityaccommodation
        // );
        console.log({ city: this.city });

        const requestBody = {
          // destination: { code: country ? country.code : 'SSS' },
          geolocation: {
            latitude: this.city.coordinates_lat,
            longitude: this.city.coordinates_lng,
            radius: 50,
            unit: 'km',
          },
          stay: {
            checkIn: data.checkInDate,
            checkOut: data.checkOutDate,
          },
          occupancies: [
            {
              rooms: data.roomsCount,
              adults: data.adultsCount,
              children: data.childrenCount,
              paxes: data.paxes,
            },
          ],
          filter: {
            maxHotels: 50,
            minCategory: 3,
          },
          reviews: [ { type: 'TRIPADVISOR' } ],
        };
        console.log(requestBody);
        const res = await availabilityApi(requestBody);
        if (res.hotels && res.hotels.total > 0) {
          res.hotels.hotels.sort(
            (a, b) => b.reviews[0].rate - a.reviews[0].rate
          );
          return reduceHotelseResponse(res.hotels.hotels);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async modifySearch() {
      this.modifyLoading = true;

      const hotels = await this.loadModifySearchHotels({
        geolocation: {
          latitude: this.city.coordinates_lat,
          longitude: this.city.coordinates_lng,
          radius: 50,
          unit: 'km',
        },
        checkInDate: this.$refs.modifiedData.checkInDate,
        checkOutDate: this.$refs.modifiedData.checkOutDate,
        nightsCount: this.$refs.modifiedData.nightsCount,
        roomsCount: this.$refs.modifiedData.roomsCount,
        adultsCount: this.$refs.modifiedData.adultsCount,
        childrenCount: this.$refs.modifiedData.childrenCount,
        paxes: this.$refs.modifiedData.paxes,
      });

      const result = hotels.filter(obj => {
        return obj.code === this.selectedHotel.code;
      })[0];

      if (result) {
        this.selectedHotel = result;
      } else {
        alert('There were no results for this search.');
      }

      this.modifyLoading = false;
    },
    onChangeNightCount($event) {
      const checkinDate = new Date(this.checkInDate);
      this.checkOutDate = dateForCalendar(
        new Date(checkinDate.setDate(checkinDate.getDate() + $event))
      );
      this.nightsCount = $event;
    },
    handleScroll(el) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop + 300 >=
        el.srcElement.scrollHeight
      ) {
        this.batchCount += 1;
      }
    },
    ...mapActions([
      'loadAvailabilityHotels',
      'loadExperiences',
      'addExperienceToCart',
      'roomsChecking',
    ]),
    async addToCard(hotelData) {
      if (!this.accommodationsInCart.length) {
        let hotel = hotelData;
        let rates;
        if (hotel.rateType === 'RECHECK') {
          rates = await this.roomsChecking({
            rooms: [ { rateKey: hotel.rateKey } ],
          });
        }
        if (rates) {
          hotel.net = rates.net;
          hotel.rateKey = rates.rateKey;
          hotel.rateType = rates.rateType;
          hotel.sellingRate = rates.sellingRate;
        }

        this.addExperienceToCart({
          id: hotel.rateKey,
          name: hotel.name,
          // price:
          //   (this.$refs.modifiedData
          //     ? this.$refs.modifiedData.nightsCount
          //     : this.nightsCount) *
          //   (hotel.sellingRate ? hotel.sellingRate : hotel.net),
          price: hotel.net,
          image: hotel.image.path,
          quantity: 1,
          type: 'accommodation',
          details: {
            destination: this.userDestination,
            checkInDate: this.modalShowing
              ? this.$refs.modifiedData.checkInDate
              : this.checkInDate,
            checkOutDate: this.modalShowing
              ? this.$refs.modifiedData.checkOutDate
              : this.checkOutDate,
            nightsCount: this.modalShowing
              ? this.$refs.modifiedData.nightsCount
              : this.nightsCount,
            roomsCount: this.modalShowing
              ? this.$refs.modifiedData.roomsCount
              : this.roomsCount,
            adultsCount: this.modalShowing
              ? this.$refs.modifiedData.adultsCount
              : this.adultsCount,
            childrenCount: this.modalShowing
              ? this.$refs.modifiedData.childrenCount
              : this.childrenCount,
            paxes: this.modalShowing
              ? this.$refs.modifiedData.paxes
              : this.paxes,
            ...hotel,
          },
        });

        this.$store.commit('toggleDrawer', true);
        this.setActiveTab('activities');
      } else {
        this.showingInfo = true;
      }
    },
    handleHotelCardClick(hotel) {
      this.selectedHotel = hotel;
      this.modalShowing = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;

  padding: 25px;
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-80;
  z-index: 98;
}

.v-form {
  padding: 0 40px 40px 40px;
  display: flex;
  max-width: 1403px;
  margin: auto;
}

.v-form > .user-input-container {
  margin-right: 16px;
}

.v-btn {
  height: 64px !important;
}

.accommodation-location-selector {
  .v-input {
    margin-top: -19px !important;
    margin-left: -6px !important;
  }
}

.user-input-container {
  background: #f8f8f8 !important;
  border: 1px solid #e3e3e4 !important;
  border-radius: 10px;
  height: 64px;
  display: flex;
  max-width: 400px;

  & > div:not(.divider) {
    padding: 4px 4px 0 4px;
  }
  .divider {
    background: #747378;
    opacity: 0.2;
    height: 36px;
    width: 2px;
    margin-top: 14px;
  }
}

.guests-container {
  width: 344px;
  min-width: 194px;
}

@media (max-width: 1096px) {
  .guests-container {
    width: unset;
  }
  .user-input-container {
    max-width: none;
    margin-bottom: 12px;
    & > div:not(.divider) {
      flex-grow: 1;
    }
  }
  .v-form {
    flex-direction: column;
  }
}

.max-width-none {
  max-width: none;
}

.modify-search {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 32px;
  gap: 12px;
}

.forSafari {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      margin-top: 7.5rem !important;
    }
  }
}

.input-title {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #414145;
  margin-top: 10px;
  padding-bottom: 3px;
}

.guest-select {
  flex-direction: column;
  align-items: baseline;
  padding-right: 78px;
  padding-left: 26px;
  position: relative;
  overflow: visible;
  z-index: 1;
  user-select: none;
  position: absolute;
  ul {
    padding-left: 0 !important;
  }
  li {
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
    padding: 16px 0;
    font-family: MontserratMedium;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #111216;
  }

  label {
    font-family: MontserratMedium;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: #414145;
    position: absolute;
    left: 0;
    top: 28px;
  }
}

.room-guest-count {
  padding-top: 3px;
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #111216;
  cursor: pointer;
}

.guest-dropdown {
  position: absolute !important;
  top: 65px;
  background: #fff;
  width: 100%;
  left: 0;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 12px 24px rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding: 24px !important;
}

.decrease {
  margin-left: auto;
}

.add,
.decrease {
  background: #ffe4ec;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  svg {
    margin: auto;
  }
}

.count {
  min-width: 36px;
  display: flex;
  justify-content: center;
}

.proceed-btn {
  border-radius: 104px !important;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: none !important;
}
.proceed-btn {
  width: 100%;
  margin: 48px 0 0 0;
  height: 36px !important;
}

.container {
  padding-bottom: 200px;
}
</style>
