<template>
  <v-card>
    <div class="tw-relative">
      <v-img
        v-if="!imageError"
        @error="
          () => {
            imageError = true;
          }
        "
        class="tw-cursor-pointer "
        :src="hotel.image.path"
        @click="handleClick"
      ></v-img>
      <v-img
        v-else-if="hotel.hotelImage"
        class="tw-cursor-pointer "
        :src="hotel.hotelImage.path"
        @click="handleClick"
      ></v-img>
      <v-img
        v-else
        class="tw-cursor-pointer "
        :src="hotel.images[0].path"
        @click="handleClick"
      ></v-img>
    </div>
    <div class="tw-flex  tw-flex-grow tw-p-6">
      <!-- left side -->

      <div class="tw-flex-grow">
        <div>
          <v-rating
            :value="hotel.categoryName"
            color="#D62A53"
            dense
            half-increments
            readonly
            background-color="#EB94A9"
            size="20"
            empty-icon="$ratingFull"
          ></v-rating>

          <v-card-title @click="handleClick">
            <div
              class="
            tw-font-poppins tw-font-semibold
            tw-text-title
            tw-font-semibold
            tw-text-2xl
            tw-p-0
            tw-cursor-pointer
            tw-pl-0 
            tw-text-left
            hotel-name
          "
            >
              {{ this.truncate(this.hotel.name, 15) }}
            </div>
          </v-card-title>
        </div>
        <v-card-text classs="tw-cursor-pointer" @click="handleClick">
          <review-rating :hotel="hotel" />
        </v-card-text>
        <div
          class="
        tw-font-montserrat-semi-bold tw-text-title tw-font-semibold tw-text-left tw-pl-0
        title-data tw-text-base
        tw-cursor-pointer
      "
          @click="handleClick"
        >
          {{ this.hotel.roomName }} {{ ' ' + this.hotel.boardCode }}
        </div>
        <div
          class="tw-font-montserrat-medium tw-text-xs tw-text-left tw-text-paragraph tw-cursor-pointer"
          @click="handleClick"
        >
          <template v-if="isCancellationPolicy">
            Free cancellation until {{ this.hotel.cancellationPolicy }}
          </template>

          <template v-else>No refund available</template>
        </div>
      </div>

      <!-- right side -->
      <div class="tw-flex tw-flex-col	tw-min-w-max w-24">
        <div
          class="tw-flex tw-flex-grow tw-flex-col tw-cursor-pointer"
          @click="handleClick"
        >
          <div
            class="
              tw-text-right
              tw-font-poppins tw-font-semibold
              tw-text-title
              tw-font-semibold
              tw-text-2xl	
              tw-leading-8
              tw-h-8
            "
          >
            <template v-if="this.hotel.sellingRate">
              {{
                this.hotel.currency.replace('USD', '$').replace('EUR', '€') +
                  roundToTwo(this.hotel.sellingRate / this.nightsCount)
              }}
            </template>
            <template v-else>
              {{
                this.hotel.currency.replace('USD', '$').replace('EUR', '€') +
                  roundToTwo(this.hotel.net / this.nightsCount)
              }}
            </template>
          </div>
          <div
            class="
              tw-text-right tw-font-montserrat-semi-bold tw-text-paragraph tw-font-medium tw-text-sm 
            "
          >
            Per Night
          </div>
        </div>

        <div class="tw-inline-flex add-btn" @click="addToCart">
          <span>
            <CartIcon class="tw-inline-block tw-h-4 tw-w-4 tw-mr-3" />
            <span class="tw-inline-block tw-h-4 tw-leading-4">Add to cart</span>
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// import GoodIcon from '@/assets/inspirationPage/good.svg';
import CartIcon from '@/assets/inspirationPage/cart.svg';
// import FlatButton from '../../FlatButton.vue';
import ReviewRating from './ReviewRating.vue';
export default {
  data: () => ({
    loading: false,
    selection: 1,
    imageError: false
  }),
  props: [ 'hotel', 'nightsCount' ],
  components: {
    // GoodIcon,
    // FlatButton,
    CartIcon,
    ReviewRating
  },

  computed: {
    isCancellationPolicy: function() {
      return (
        new Date(this.hotel.cancellationPolicies[0].from).getDate() >=
        new Date().getDate()
      );
    }
  },
  methods: {
    getImage: name => require('@/assets/inspirationPage/' + name),
    addToCart: function() {
      console.log(this.hotel);

      this.$emit('addToCard', this.hotel);
    },
    truncate: function(str, n) {
      return str.length > n ? str.substr(0, n) + '...' : str;
    },
    handleClick: function() {
      this.$emit('click', this.hotel);
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    }
  }
};
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 10px;
  padding-left: 0px;
}

.v-card__text {
  padding-left: 0;
}

.text-data {
  line-height: 0rem;
}
.provisor {
  height: 17px;
}

.v-rating {
  text-align: start;
  margin-left: -2px;
}

.v-card {
  border: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06) !important;
  border-radius: 18px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
}

.v-card > div:first-child {
  height: 16.35vw;
  max-height: 332px;
}
.v-card > div:last-child {
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}

.v-image {
  border-radius: 18px 18px 0px 0px;
  max-width: 520px;
  height: 16.35vw;
  max-height: 332px;
}

.flat .v-btn {
  background: transparent;
}

.hotel-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.add-btn {
  background: #d62a53;
  border-radius: 90px;
  width: 146px;
  height: 34px;
  color: white;
  display: flex;
  transition: 0.3s;
  cursor: pointer;

  & > * {
    margin: auto;
  }
}

.add-btn:hover {
  opacity: 0.9;
}
.add-btn:active {
  opacity: 0.75;
}
</style>
