import { MapElementFactory } from 'gmap-vue';

export default MapElementFactory({
  name: 'directionsRenderer',

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: [ String ] },
    destination: { type: [ String ] },
  },

  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService();

    this.$watch(
      () => [ this.origin, this.destination ],
      () => {
        let { origin, destination } = this;
        if (!origin || !destination) return;
        directionsService.route(
          {
            origin,
            destination,
          },
          (response, status) => {
            if (status !== 'OK') return;
            // eslint-disable-next-line no-debugger
            debugger;
            directionsRenderer.setDirections(response);
          }
        );
      }
    );
  },
});
