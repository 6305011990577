<template>
  <v-img
    v-if="!imageError"
    @error="
        imageError = true;
    "
    :src="src.replace('medium', 'xl')"
  ></v-img>
  <v-img
    v-else-if="!imageError2"
    @error="
        imageError2 = true;
    "
    :src="src.replace('medium', 'bigger')"
  ></v-img>
  <v-img
    v-else-if="!imageError3"
    @error="
        imageError3 = true;
    "
    :src="src"
  ></v-img>
  <v-img
    v-else-if="!imageError4"
    @error="
        imageError4 = true;
    "
    :src="src.replace('medium', 'original')"
  ></v-img>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
    imageError: false,
    imageError2: false,
    imageError3: false,
    imageError4: false,
  }),
  props: {
    src: String
  }
};
</script>

<style></style>
