<template>
  <div class="tw-mt-5">
    <div class="tw-flex">
      <v-expand-transition>
        <p
          v-html="descriptionToShow"
          class="tw-text-md tw-font-sfprotext-regular color"
        ></p>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'description' ],
  data: () => ({
    expand: false,
  }),
  computed: {
    descriptionToShow: function() {
      return this.expand
        ? this.description
        : this.truncate(this.description, 190);
    },
  },
  methods: {
    truncate: function(str, n) {
      return str.length > n
        ? str.substr(0, n - 1) +
            '.....' +
            ' ' +
            '<span class="tw-text-black tw-font-bold pointer tw-font-sfprotext-semibold">See more</span>'
        : str + ' ' + '';
      // '<span class="tw-text-black tw-font-bold it-collapse tw-font-montserrat-semi-bold">Back</span>';
    },
  },
  mounted() {
    document.querySelector('.pointer').addEventListener(
      'click',
      function() {
        this.expand = true;
      }.bind(this)
    );
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.collapse {
  cursor: pointer;
}
p {
  word-break: break-word;
  white-space: normal;
}
.color {
  color: #747378;
  font-size: 1rem;
  width: 90%;
  line-height: 1.5rem;
}
</style>
