<template>
  <div class="tw-flex">
    <div v-for="(item, index) in items"
    :key="`radio_${index}`"
    class="tw-flex tw-items-center tw-mr-6 tw-cursor-pointer"
    @click="$emit('input', item)">
    <div class="tw-mr-2">
      <EmptyOptionIcon v-if="item !== value" />
      <CheckedOptionIcon v-else />
    </div>
    <div class="fontItem tw-uppercase">{{item}}</div>
    </div>
  </div>
</template>

<script>
import EmptyOptionIcon from '@/assets/bookingPage/flights/empty-option.svg';
import CheckedOptionIcon from '@/assets/bookingPage/flights/checked-option.svg';

export default {
  components: {
    EmptyOptionIcon,
    CheckedOptionIcon,
  },
  props: {
    items: Array,
    value: String,
  },
  data: ()=> ({
  }),
};
</script>

<style lang="scss" scoped>
.fontItem {
  font-family: 'MontserratSemiBold' !important;
}
</style>
