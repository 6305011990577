<template>
  <div class="hidden-xs-only">
    <div id="destination-page hidden-xs-only" v-if="mode === 'Discover'">
      <DiscoverCity v-if="!!cityName" :city="cityName" />
    </div>
    <div id="destination-page hidden-xs-only" v-if="mode === 'Booking'">
      <BookingCityTab v-if="!!cityName" :city="cityName" />
    </div>
    <div id="destination-page hidden-xs-only" v-if="mode === 'Map'">
      <MapCityTab v-if="!!cityName" :city="cityName" />
    </div>
    <div class="content">
      <div class="tw-flex tw-justify-center tw-items-center">
        <ul class="tab-list ">
          <li
            class="tw-relative link tw-text-sm tw-text-center"
            v-for="(tab, index) in availableTabs"
            :class="{ active: index === tabIndexMap.get(activeTab) }"
            :key="tab.id"
          >
            <button
              @click="activeTab = tab.id"
              class="tw-flex icon-container
                      tw-rounded-full tw-bg-white tw-h-12 tw-my-1 tw-items-center tw-justify-center"
            >
              <div class="tw-pl-2">
                <component
                  v-if="index === tabIndexMap.get(activeTab)"
                  :is="tab.iconWhite"
                />
                <component v-else :is="tab.icon" />
              </div>
              <div class="label tw-font-poppins-medium tw-pr-2">
                {{ tab.label }}
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div
        class="
          tw-flex tw-flex-col tw-w-full tw-mb-8
          md:tw-mb-0
          tw-mt-6
          md:tw-mt-0
        "
      >
        <offers-container
          :active-tab="activeTab"
          :experience="destinationExperience"
          :city="city"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DiscoverCity from '@/views/CityPage/DiscoverCity';
import BookingCityTab from '@/views/CityPage/BookingCityTab';
import MapCityTab from '@/views/CityPage/MapCityTab';
import { mapActions, mapGetters } from 'vuex';
import OffersContainer from '../inspiration/OffersContainer.vue';
import FlightIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import NightlifeIcon from '@/assets/inspirationPage/tabicons/nightlife.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import moment from 'moment';
import AccommodationIconWhite from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import ActivitiesIconWhite from '@/assets/inspirationPage/tabicons/activities_w.svg';
import NightlifeIconWhite from '@/assets/inspirationPage/tabicons/nightlife_w.svg';
import RestaurantIconWhite from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import FlightIconWhite from '@/assets/inspirationPage/tabicons/plane_w.svg';
import axios from 'axios';
import { dateForCalendar } from '@/utils/appDate.js';
export default {
  name: 'DestinationPage',
  data() {
    return {
      cityName: null,
      tabIndexMap: new Map([
        [ 'flights', 0 ],
        [ 'accommodation', 1 ],
        [ 'activities', 2 ],
        [ 'restaurants', 3 ],
        [ 'events', 4 ]
      ]),
      activeTab: 'accommodation',
      availableTabs: [
        {
          id: 'flights',
          label: 'Flights',
          icon: FlightIcon,
          iconWhite: FlightIconWhite
        },
        {
          id: 'accommodation',
          label: 'Accommodation',
          icon: AccommodationIcon,
          iconWhite: AccommodationIconWhite
        },
        {
          id: 'activities',
          label: 'Activities',
          icon: ActivitiesIcon,
          iconWhite: ActivitiesIconWhite
        },
        {
          id: 'restaurants',
          label: 'Restaurants',
          icon: RestaurantIcon,
          iconWhite: RestaurantIconWhite
        },
        {
          id: 'events',
          label: 'Events',
          icon: NightlifeIcon,
          iconWhite: NightlifeIconWhite
        }
      ],
      nightsCount: 1,
      roomsCount: 1,
      adultsCount: 1,
      childrenCount: 0,
      cities: [],
      dateFrom: '',
      dateTo: '',
    };
  },
  components: {
    DiscoverCity,
    BookingCityTab,
    MapCityTab,
    FlightIcon,
    AccommodationIcon,
    ActivitiesIcon,
    NightlifeIcon,
    RestaurantIcon,
    OffersContainer,
    AccommodationIconWhite,
    ActivitiesIconWhite,
    NightlifeIconWhite,
    RestaurantIconWhite
  },
  computed: {
    ...mapGetters([ 'experiences', 'experience' ]),
    destinationExperience: function() {
      const exp = this.experiences.find(
        it => it.city_id === this.experience.city_id
      );
      return {
        ...this.experience,
        restaurants: exp.experiences
          .reduce((acc, value) => [ ...acc, ...value.restaurants ], [])
          .filter(it => it.description !== ''),
        activities: exp.experiences
          .reduce((acc, value) => [ ...acc, ...value.activities ], [])
          .filter(it => it.description !== '')
      };
    },
    mode() {
      return this.$route.query.mode;
    },

    city: {
      get() {
        try {
          return this.cities.data[this.experience.city_id];
        } catch (err) {
          return null;
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'loadExperiences',
      'loadAvailabilityHotels',
      'flights',
      [ 'searchFlights' ]
    ]),
    async searchFlightsRequest() {
      this.loading = true;
      const payload = {
        fly_from: 'Berlin',
        fly_to: this.experience.flight_destination,
        departure_date:
          this.dateFrom,
        return_date:
          this.dateTo,
        max_stopovers: 10,
        max_fly_duration: 50
      };

      try {
        this.flights = await this.searchFlights(payload);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
    async findHotels() {
      this.$store.commit('setIsNoContent', false);
      await this.loadAvailabilityHotels({
        geolocation: {
          latitude: this.city.coordinates_lat,
          longitude: this.city.coordinates_lng,
          radius: 50,
          unit: 'km'
        },
        checkInDate:
          this.from ||
          moment()
            .add(30, 'd')
            .format('YYYY-MM-DD'),
        checkOutDate:
          this.to ||
          moment()
            .add(33, 'd')
            .format('YYYY-MM-DD'),
        nightsCount: this.nightsCount,
        roomsCount: this.roomsCount,
        adultsCount: this.adultsCount,
        childrenCount: this.childrenCount
      });
    }
  },
  async created() {
    if (this.$route.query) {
      this.$store.commit('search/setParams', {
        ...this.$route.query,
        maxPrice: parseFloat(this.$route.query.maxPrice)
      });
    }
  },
  async mounted() {

    const day = 60 * 60 * 24 * 1000 * 30;
    const dayTo = 60 * 60 * 24 * 1000 * 3;
    this.dateTemp = dateForCalendar();
    this.dateFrom = dateForCalendar(
      new Date(new Date(this.dateTemp).getTime() + day)
    );
    this.dateTo = dateForCalendar(
      new Date(new Date(this.dateFrom).getTime() + dayTo)
    );

    this.$store.commit('setAvailabilityHotels', []);
    if (!this.experiences?.length) {
      let interests = this.$store.state.search.interests;
      interests.length < 1 && (interests = '1-2-3-4-5-6-7-8-9');
      await this.loadExperiences(interests);
    }

    this.cities = await axios.get(
      'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
    );

    const { cityName } = this.$route.params;
    const experience = this.experiences.find(it => it.name === cityName)
      .experiences[0];
    this.$store.commit('setExperience', experience);
    this.cityName = this.experiences.find(exp => exp.name === cityName);

    if (!this.cityName || !this.experience) {
      this.$router.push({ name: 'LandingPage' });
    }
    this.$store.commit('flights/setSearchedFlights', []);
    await this.searchFlightsRequest();
    await this.findHotels();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.content {
  margin: auto;
}

.tips-and-tricks {
  @apply tw-font-sans tw-text-gray-700 tw-font-bold;
}

.label {
  user-select: none;
  margin: auto 0.5rem;
  font-size: 0.75vw;
}

.tab-list {
  @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-0 tw-mt-12 tw-border tw-rounded-full tw-px-2;
  max-width: 52vw;
  width: 100%;
  margin-bottom: 56px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}

.header-section {
  padding-top: 120px;

  .left-column {
    @screen md {
      @apply tw-w-1/2 tw-pl-28 tw-pt-20;
    }

    .avatar {
      box-shadow: 0px 0px 5px black;
      border-radius: 150px;
      width: 250px;
      height: 250px;
    }

    .experience {
      color: #333333;
    }

    .action-bar {
      flex-grow: 0.5;
    }
  }

  .right-column {
    .story {
      box-shadow: 0 0 4px black;
      border-radius: 10px;
    }
  }
}

.link {
  display: block;

  @include respond-to(xs-sm) {
    display: flex;
    align-items: center;
  }

  .icon-container {
    color: #28262e;
    width: 10vw;
  }

  &.active {
    .icon-container {
      background: #d62a53;
      color: white;
    }
    .label {
      color: white;
    }
  }

  button {
    color: currentColor;
    width: 48px;

    svg {
      width: 20px;
      height: 48px;
      margin: auto;
      margin-left: 0.5rem;
    }
  }
}

li {
  padding: 0;
}
</style>
