<template>
  <v-select
    v-model="select"
    class="select"
    :items="values"
    :label="label"
    filled
    attach
    dense
    :menu-props="{ bottom: true, offsetY: true }"
  >
    <template v-slot:append> <ArrowIcon /> </template
    ><template v-slot:label>
      <div
        class="tw-text-xs tw-font-montserrat-semi-bold tw-text-paragraph tw-text-left tw-text-black title-text"
      >
        {{ label }}
      </div>
    </template></v-select
  >
</template>

<script>
import ArrowIcon from '@/assets/inspirationPage/arrow-select.svg';
export default {
  props: [ 'values', 'value', 'label' ],
  components: {
    ArrowIcon
  },
  computed: {
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      }
    }
  }
};
</script>

<style scoped>
.select {
  padding: 0 !important;
}
.select::v-deep .v-input__slot {
  border: none !important;
  margin: 0;
  border-radius: 5px;
  padding: 5px 22px !important;
  background: transparent !important;
}
.select::v-deep .v-input__slot::before {
  border: none !important;
}
.select::v-deep .v-input__slot::after {
  border: none !important;
}
.select::v-deep .v-select__selection {
  margin: 0 !important;
}
.select::v-deep .v-text-field__details {
  display: none !important;
}
.select::v-deep .v-input__append-inner {
  padding-top: 15px;
}
.select::v-deep .v-label {
  color: black;
}
.title-text {
  font-size: 15px;
}
</style>
