<template>
  <flights-control-container class="tw-relative">
    <flights-date-picker-item
    label="Departure"
    v-model="dateFromVal"
    :min="today"
    />
    <flights-control-container-divider />
    <flights-date-picker-item
    label="Return"
    v-model="dateToVal"
    :min="dateFromVal"
    :disabled="noReturn"
    />
  </flights-control-container>
</template>

<script>
import moment from 'moment';
import FlightsControlContainer from './FlightsControlContainer.vue';
import FlightsControlContainerDivider from './FlightsControlContainerDivider.vue';
import FlightsDatePickerItem from './FlightsDatePickerItem.vue';

export default {
  props: {
    rules: Array,
    dateFrom: String,
    dateTo: String,
    noReturn: Boolean,
  },
  data: () => ({
  }),
  components:{
    FlightsDatePickerItem,
    FlightsControlContainer,
    FlightsControlContainerDivider,
  },
  computed: {
    dateFromVal: {
      get() { return this.dateFrom; },
      set(val) {
        this.$emit('input', 'dateFrom', val);
      }
    },
    dateToVal: {
      get() { return this.dateTo; },
      set(val) {
        this.$emit('input', 'dateTo', val);
      }
    },
    today() {
      return moment().format('YYYY-MM-DD');
    },
  },
  watch: {
    
    FlightsControlContainerdateFrom(val) {
      console.log(val);
    }
  }
};
</script>
