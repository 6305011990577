import { render, staticRenderFns } from "./HotelItem.vue?vue&type=template&id=6e9e6fba&scoped=true&"
import script from "./HotelItem.vue?vue&type=script&lang=js&"
export * from "./HotelItem.vue?vue&type=script&lang=js&"
import style0 from "./HotelItem.vue?vue&type=style&index=0&id=6e9e6fba&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9e6fba",
  null
  
)

export default component.exports