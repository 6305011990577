<template
  ><span>
    <div class="tw-flex tw-justify-between modify-search">
      <div class="user-input-container">
        <div>
          <DatePicker
            v-model="checkInDate"
            :min="minCheckIn"
            label="CHECK-IN"
            :rules="[dateFromRules.compareDates(checkInDate, checkOutDate)]"
          />
        </div>
        <div class="divider"></div>
        <div>
          <DatePicker
            v-model="checkOutDate"
            :min="checkInDate"
            label="CHECK-OUT"
            :rules="dateCheckOutRules"
          />
        </div>
      </div>
      <div class="user-input-container ">
        <div>
          <Select
            :values="roomsCounts"
            :value="roomsCount"
            @update="roomsCount = $event"
            label="ROOMS"
          />
        </div>
        <div class="divider"></div>

        <div>
          <Select
            :values="adultsCounts"
            :value="adultsCount"
            @update="adultsCount = $event"
            label="ADULTS"
          />
        </div>
        <div class="divider"></div>

        <div>
          <Select
            :values="childrenCounts"
            :value="childrenCount"
            @update="childrenCount = $event"
            label="CHILDREN"
          />
        </div>
      </div>


      <FlatButton @click="modifySearch" :disabled="!isValid || modifyLoading">
        <template #icon>
          <ModifySearchIcon />
        </template>
        Modify Search
      </FlatButton>
    </div>
    <div
      class="v-form tw-flex tw-justify-between"
      v-if="childrenAges.length > 0"
    >
      <div class="user-input-container max-width-none">
        <div
          class="tw-contents"
          v-for="(item, index) in childrenAges"
          :key="item + index"
        >
          <div class="divider" v-if="index > 0"></div>

          <select-age
            :values="childAge"
            :value="paxes[index].age"
            @update="paxes[index].age = $event"
            :rules="ageRules"
            :label="`AGE CHILD ${index + 1}`"
          />
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import ModifySearchIcon from '@/assets/hotelDetails/search.svg';
import FlatButton from '../FlatButton';
import DatePicker from './components/DatePicker.vue';
import Select from './components/Select.vue';
import SelectAge from './components/SelectAge.vue';
import { diffDays } from '@/utils/appDate.js';

export default {
  components: {
    ModifySearchIcon,
    FlatButton,
    DatePicker,
    Select,
    SelectAge
  },
  data: function() {
    return {
      checkInDate: this.checkInDateInitial,
      minCheckIn: this.minCheckInInitial,
      dateFromRules: this.dateFromRulesInitial,
      checkOutDate: this.checkOutDateInitial,
      dateCheckOutRules: this.dateCheckOutRulesInitial,
      roomsCounts: this.roomsCountsInitial,
      childrenCounts: this.childrenCountsInitial,
      modifySearch: this.modifySearchInitial,
      isValid: this.isValidInitial,
      childrenAges: this.childrenAgesInitial,
      paxes: this.paxesInitial,
      childAge: this.childAgeInitial,
      roomsCount: this.roomsCountInitial,
      adultsCount: this.adultsCountInitial,
      childrenCount: this.childrenCountInitial,
      adultsCounts: this.adultsCountsInitial,
      ageRules: this.ageRulesInitial,
      nightsCount: this.nightsCountInitial
    };
  },
  props: [
    'checkInDateInitial',
    'minCheckInInitial',
    'dateFromRulesInitial',
    'checkOutDateInitial',
    'dateCheckOutRulesInitial',
    'roomsCountsInitial',
    'childrenCountsInitial',
    'modifySearchInitial',
    'isValidInitial',
    'childrenAgesInitial',
    'paxesInitial',
    'childAgeInitial',
    'roomsCountInitial',
    'adultsCountInitial',
    'childrenCountInitial',
    'adultsCountsInitial',
    'ageRulesInitial',
    'nightsCountInitial',
    'modifyLoading',
    'findHotels'
  ],
  watch: {
    nightsCount: function(newValue) {
      this.nightsCounts =
        newValue < 10
          ? new Array(10).fill(1).map((it, i) => i + 1)
          : new Array(newValue + 1).fill(1).map((it, i) => i + 1);
      this.findHotels();
    },
    checkInDate: function(newValue) {
      const dif = diffDays(this.checkOutDate, newValue);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(newValue) <= new Date(this.checkOutDate);
      if (!this.isaccommodationSearching) this.findHotels();
    },
    checkOutDate: function(newValue) {
      const dif = diffDays(newValue, this.checkInDate);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(this.checkInDate) <= new Date(newValue);
      this.findHotels();
    },
    roomsCount: function() {
      this.findHotels();
    },
    adultsCount: function() {
      this.findHotels();
    },
    childrenCount: function() {
      this.findHotels();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;

  padding: 25px;
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-80;
  z-index: 98;
}

.v-form {
  padding: 0 40px 40px 40px;
  display: flex;
  max-width: 1403px;
  margin: auto;
}

.v-form > .user-input-container {
  margin-right: 16px;
}

.v-btn {
  height: 64px !important;
}

.accommodation-location-selector {
  .v-input {
    margin-top: -19px !important;
    margin-left: -6px !important;
  }
}

.user-input-container {
  background: #f8f8f8 !important;
  border: 1px solid #e3e3e4 !important;
  border-radius: 10px;
  height: 64px;
  display: flex;
  max-width: 400px;

  & > div:not(.divider) {
    padding: 4px 4px 0 4px;
  }
  .divider {
    background: #747378;
    opacity: 0.2;
    height: 36px;
    width: 2px;
    margin-top: 14px;
  }
}

@media (max-width: 1096px) {
  .user-input-container {
    max-width: none;
    margin-bottom: 12px;
    & > div:not(.divider) {
      flex-grow: 1;
    }
  }
  .v-form {
    flex-direction: column;
  }
}

.max-width-none {
  max-width: none;
}

.modify-search {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 32px;
  gap: 12px;
}
</style>
