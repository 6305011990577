<template>
  <div class="slide">
    <video
      playsinline
      ref="video"
      :src="item"
      class="video"
      :poster="require('@/assets/destinationPage/loader/bg-min.jpg')"
      @loadeddata="loadedData"
    >
      Your browser does not support the HTML5 Video element.
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoSlide',
  props: {
    item: String,
    index: Number
  },
  methods: {
    show() {
      if (this.$refs.video) {
        this.$refs.video.currentTime = 0;
        if (this.$refs.video.readyState !== 4) {
          this.$refs.video.load();
        }
        this.$refs.video.play();
      }
    },
    hide() {
      if (this.$refs.video) {
        if (this.$refs.video.currentTime > 0 && !this.$refs.video.paused && !this.$refs.video.ended && this.$refs.video.readyState > 2) {
          this.$refs.video.pause();
        }
      }
    },
    loadedData() {
      if (this.$refs.video) {
        this.$emit('duration-changed', this.$refs.video.duration);
      }
    }
  },
  mounted() {
    if (this.$refs.video && this.index === 0) {
      this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
      var nopromise = {
        catch : new Function()
      };
      (this.$refs.video.play() || nopromise).catch(function() {}); 
      
    }
  }
};
</script>

<style lang="scss" scoped>
.slide {
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
