<template>
  <v-select
    class="text-field"
    v-model="text"
    :label="label"
    single-line
    filled
    :disabled="disabled"
    :rules="rules"
    ><template v-slot:prepend-inner> <SearchIcon /> </template>
    <template v-slot:label>
      <div
        class="label-inner tw-text-xs tw-font-montserrat-semi-bold tw-text-paragraph tw-text-left tw-text-black title-text"
      >
        {{ label }}
      </div>
    </template></v-select
  >
</template>

<script>
import SearchIcon from '@/assets/inspirationPage/search.svg';
export default {
  components: {
    SearchIcon
  },
  props: [ 'rules', 'value', 'disabled', 'label' ],
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-field::v-deep .v-input__slot {
  border: none !important;
  padding: 0px 22px !important;
  border-radius: 5px;
  background: transparent !important;
  margin: 0 4px;
  input {
    font-family: MontserratMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #28262e !important;
  }
}
.text-field::v-deep .v-input__slot:before {
  border: none !important;
}
.text-field::v-deep .v-input__slot:after {
  border: none !important;
}
.text-field::v-deep .v-text-field__slot {
  padding-left: 10px !important;
}

.title-text {
  font-size: 15px;
}

.label-inner{
      font-family: 'MontserratMedium';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    color: #111216;
    text-transform: none;
    
}
</style>
