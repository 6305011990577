<template>
  <div class="modal">
    <div class="container">
      <div class="tw-px-8">
        <div class="tw-font-semibold modal-title tw-mt-2">
          Can not add multiple hotels to one booking. Please remove the first
          hotel to add a new one.
        </div>
        <div
          @click="$emit('close')"
          class="tw-w-14 tw-mt-4 tw-p-4 closeButton tw-bg-primary tw-h-12 tw-flex tw-justify-center tw-align-center tw-text-white"
        >
          Close
        </div>
      </div>
    </div>
    <div
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-gray-600 tw-bg-opacity-50 tw-overflow-y-auto tw-h-full tw-w-full"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'AddHotelModal'
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';
.modal-title {
  font-size: 32px;
  font-family: 'RedHatDisplayMedium';
  margin: auto;
  text-align: center;
  line-height: 32px;
  font-weight: 700;
}

.updateButton {
  font-size: 20px;
  height: 3.5rem !important;
}

.closeButton {
  //   color: #747378;
  //   background-color: transparent !important;
  border: unset;
  border-color: transparent !important;
  box-shadow: unset !important;
  border-radius: 50px !important;
  font-family: 'MontserratSemiBold';
  font-size: 18px;
  text-align: center;
  //   letter-spacing: -0.02em;
  width: 100%;
  cursor: pointer;
}

.timeLabel {
  color: black;
  font-size: 14px;
  font-family: 'RedHatDisplayMedium';
}

select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 96%;
}

.dateCircles {
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  box-shadow: none !important;
  background: #ffe4ec;
  color: #d62a53;
  text-align: center;
  font-family: MontserratMedium;
  font-size: 18px;  
}

.dateCircles:hover {
  background-color: $primary;
}

.experienceName {
  font-family: 'RedHatDisplayBold';
  font-size: 14px;
}

.dayButton {
  width: 50px;
  height: 50px;
}

.dayButton:focus {
  background-image: $primary;
}
</style>
