<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    class="calendar"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="text-field tw-text-lg tw-font-montserrat-medium tw-text-title"
        :label="label"
        :value="formatValue"
        readonly
        dense
        filled
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        ><template v-slot:label>
          <div
            class="
              tw-text-xs
              tw-font-montserrat-semi-bold
              tw-text-paragraph
              tw-text-paragraph
              tw-text-left
              tw-text-black
              title-text
            "
          >
            {{ label }}
          </div>
        </template></v-text-field
      >
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      @input="menu = false"
      no-title
      class="date-picker"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import { dateForAvailabilityCalendar } from '@/utils/appDate.js';
export default {
  props: {
    label: String,
    value: String,
    min: String,
    rules: Array,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
      },
    },
    formatValue: function() {
      return dateForAvailabilityCalendar(new Date(this.value));
    },
  },
};
</script>

<style scoped>
/* .v-picker {
  width: 380px;
} */

.text-field {
  padding: 0;
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #28262e;
  margin-top: -6px;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #414145;
  padding-bottom: 3px;
}
.text-field::v-deep .v-input__slot {
  border: none !important;
  padding: 7px 22px !important;
  border-radius: 5px;
  background: transparent !important;
}
.text-field::v-deep .v-input__slot:before {
  border: none !important;
}
.text-field::v-deep .v-input__slot:after {
  border: none !important;
}
.text-field::v-deep input {
  padding: 0 !important;
  font-size: 13px;
}

.text-field::v-deep .v-label {
  color: black;
}
.title-text {
  font-size: 15px;
}
</style>
