<template>
  <div v-if="isCity" class="city-steps-container tw-flex tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center tw-font-poppins-medium tw-mb-10 hidden-xs-only">
    <div
    v-for="(tab, index) in tabs.slice(0,2)"
    :key="`search_link_${index}`"
    class="tw-flex tw-items-center"
    :class="{'completed': completed(index)}"
    >
      <router-link 
        v-if="index === 1"
        :to="{name: tab.routeName, query: { mode: 'Booking'}}"
      ><div class="currentIndex tw-mr-3">{{city.name}}, {{city.country}}</div></router-link>
      <div v-else @click="handleGoBack()">
        <div class="tw-mr-3 tw-cursor-pointer">{{tab.title}}</div>
      </div>
      <template v-if="index < tabs.length - 2"
      ><shevron-right-icon
        class="tw-mr-3"
        /></template>
    </div>
  </div>
  <div v-else class="steps-container tw-flex tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center tw-font-poppins-medium tw-mb-10">
    <div
    v-for="(tab, index) in tabs"
    :key="`search_link_${index}`"
    class="tw-flex tw-items-center"
    :class="{'completed': completed(index)}"
    >
     <router-link 
        v-if="index === 0"
        :to="{name: tab.routeName, query: { city: selectedCity}}"
      ><div class="tw-mr-3">{{tab.title}}</div></router-link>
    <div v-if="index === 1" @click="handleCityPage()">
      <div class="tw-mr-3 tw-cursor-pointer">{{city.name}} , {{city.country}}</div>
    </div>
    <router-link 
        v-if="index === 2"
        :to="{name: tab.routeName}"
      ><div class="currentIndex tw-mr-3">{{experience.name}}</div></router-link>
     <template v-if="index < tabs.length - 1"
      ><shevron-right-icon
        class="tw-mr-3"
        /></template>
    </div>
  </div>
</template>

<script>
import ShevronRightIcon from '@/assets/bookingPage/shevron-right.svg';

export default {
  components: { 
    ShevronRightIcon
  },
  data() {
    return {
      selectedCity: this.$route.query.city || 'Berlin',
      tabs: [
        {
          title: 'Search',
          routeName: 'DestinationFeed',
        },
        {
          title: '',
          routeName: 'DestinationPage',
        },
        {
          title: 'Influencer`s Trip',
          routeName: 'InspirationPage',
        },
      ],
    };
  },
  props: [ 'city', 'experience' ],
  methods: {
    completed(index) {
      return this.tabs.findIndex(tab => tab.routeName === this.$route.name) >= index;
    },
    handleCityPage()
    {
      this.$router.push({
        path: '/destination/' + this.city.name,
        query: { mode: 'Discover' },
      });
    },
    handleGoBack()
    {
      this.$router.go(-1);
    }
  },
  computed: {
    isCity() {
      return this.$route.name === 'DestinationPage';
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.city-steps-container {
  .completed {
    color: $gray-main;
    a div {
      color: $gray-main;
    }
    .currentIndex {
      color: white !important;
  }
  }
  margin-left: 5%;
}



.steps-container {
  .completed {
    color: $gray-main;
    a div {
      color: $gray-main;
    }
    .currentIndex {
      color: $primary !important;
  }
  }
  margin-left: 5%;
}
</style>
