<template>
  <div
    class="
      search-flights-container
      tw-p-6 tw-rounded-2xl tw-border tw-border-gray-100
      mb-8
    "
  >
    <div>
      <flights-radio-input :items="directions" v-model="directionValue" />
    </div>
    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-3 tw-mt-4">
      <div>
        <flights-location
          :locationFrom="locationFrom"
          :locationTo="locationTo"
          :airportCode="experience.flight_destination"
          @input="locationInput"
        />
      </div>
      <div>
        <flights-date-picker
          :dateFrom="dateFrom"
          :dateTo="dateTo"
          @input="datesInput"
          :noReturn="noReturn"
        />
      </div>
      <div class="tw-flex">
        <div class="tw-w-1/2">
          <flights-control-container>
            <flights-passengers-selector
              label="Passengers"
              v-model="passengers"
            />
          </flights-control-container>
        </div>
        <v-btn
          color="primary"
          rounded
          class="flight-search-button rounded-pill tw-w-1/2 tw-ml-2"
          @click="searchFlightsReq"
          :loading="loading"
          ><Search class="tw-w-4 tw-h-4 tw-mr-3" />
          <span>Search</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import FlightsRadioInput from './FlightsRadioInput.vue';
import Search from '@/assets/landingPage/search.svg';
import FlightsDatePicker from './FlightsDatePicker.vue';
import FlightsControlContainer from './FlightsControlContainer.vue';
import FlightsLocation from './FlightsLocation.vue';
import FlightsPassengersSelector from './FlightsPassengersSelector.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { dateForCalendar, convertToDatePickerFormat } from '@/utils/appDate.js';

export default {
  components: {
    FlightsRadioInput,
    Search,
    FlightsDatePicker,
    FlightsControlContainer,
    FlightsLocation,
    FlightsPassengersSelector
  },
  data: () => ({
    direction: 'return',
    directions: [ 'one way', 'return' ],
    locationFrom: '',
    locationTo: '',
    dateTemp: '',
    dateFrom: '',
    dateTo: '',
    passengers: {
      adults: 1,
      children: 0,
      infants: 0
    },
    loading: false
  }),
  props: [ 'resetLazyload' ],
  computed: {
    ...mapGetters('search', [ 'city', 'from', 'to' ]),
    ...mapGetters([ 'experience', 'cityaccommodation' ]),
    noReturn() {
      return this.direction === 'one way';
    },
    directionValue: {
      get() {
        return this.direction;
      },
      set(val) {
        if (val !== this.direction) {
          this.direction = val;
          this.searchFlightsReq();
        }
      }
    }
  },
  created() {
    this.searchFlightsReq();
    this.locationFrom = this.city || 'Berlin';
    this.locationTo = this.experience.flight_destination;
    if (this.from && this.to) {
      this.dateFrom = convertToDatePickerFormat(this.from);
      this.dateTemp = convertToDatePickerFormat(this.from);
      this.dateTo = convertToDatePickerFormat(this.to);
    } else {
      const day = 60 * 60 * 24 * 1000 * 30;
      const dayTo = 60 * 60 * 24 * 1000 * 3;
      this.dateTemp = dateForCalendar();
      this.dateFrom = dateForCalendar(
        new Date(new Date(this.dateTemp).getTime() + day)
      );
      this.dateTo = dateForCalendar(
        new Date(new Date(this.dateFrom).getTime() + dayTo)
      );
    }
  },
  mounted() {
    this.locationFrom = this.city || 'Berlin';
    this.locationTo = this.experience.flight_destination;
    if (this.from && this.to) {
      this.dateFrom = convertToDatePickerFormat(this.from);
      this.dateTemp = convertToDatePickerFormat(this.from);
      this.dateTo = convertToDatePickerFormat(this.to);
    } else {
      const day = 60 * 60 * 24 * 1000 * 30;
      const dayTo = 60 * 60 * 24 * 1000 * 3;
      this.dateTemp = dateForCalendar();
      this.dateFrom = dateForCalendar(
        new Date(new Date(this.dateTemp).getTime() + day)
      );
      this.dateTo = dateForCalendar(
        new Date(new Date(this.dateFrom).getTime() + dayTo)
      );
    }
    this.searchFlightsReq();
  },
  methods: {
    ...mapActions('flights', [ 'searchFlights' ]),
    datesInput(name, val) {
      if (name === 'dateFrom') {
        this.dateFrom = val;
        moment(val) > moment(this.dateTo) && (this.dateTo = val);
      } else {
        this.dateTo = val;
      }
      this.searchFlightsReq();
    },
    locationInput(name, val) {
      name === 'locationFrom'
        ? (this.locationFrom = val)
        : (this.locationTo = val);
    },
    async searchFlightsReq() {
      this.resetLazyload();
      this.loading = true;
      const payload = {
        fly_from: this.locationFrom,
        fly_to: this.locationTo,
        departure_date: this.dateFrom,
        return_date: !this.noReturn ? this.dateTo : null,
        max_stopovers: 10,
        max_fly_duration: 50,
        ...this.passengers
      };
      try {
        this.flights = await this.searchFlights(payload);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
    setDate(dateStr) {
      const format = 'YYYY-MM-DD';
      const date = moment(dateStr);
      return !dateStr || !date.isValid
        ? moment().format(format)
        : date.format(format);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-flights-container {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}

.rounded-pill {
  box-shadow: none !important;
}
</style>
