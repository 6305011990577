var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer"},[_c('h1',[_vm._v("About "+_vm._s(_vm.hotel.name))]),_c('p',[_vm._v(_vm._s(_vm.hotel.description.content))]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-font-poppins-semi-bold tw-text-xl tw-font-bold tw-text-left tw-mt-5"},[_vm._v(" Amenities ")]),_c('div',{staticClass:"amenities-container"},_vm._l((_vm.amenities),function(item){return _c('amenities-icon',{key:item.description,staticClass:"amenity",attrs:{"description":item.description}},[_c('Checkmark')],1)}),1)]),_c('div',{staticClass:"location"},[_c('h2',[_vm._v("Location")]),_c('p',[_vm._v(_vm._s(_vm.hotel.name))]),_c('p',[_vm._v(_vm._s(_vm.hotel.address.content))]),_c('div',{staticClass:"tw-my-8"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"244px"},attrs:{"center":{
          lat: parseFloat(_vm.hotel.latitude),
          lng: parseFloat(_vm.hotel.longitude)
        },"zoom":19}},[_c('GmapMarker',{attrs:{"position":{
            lat: parseFloat(_vm.hotel.latitude),
            lng: parseFloat(_vm.hotel.longitude)
          }}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }