<template>
  <v-row align="center" class="mx-0">
    <trip-advisor-icon class="provisor tw-mt-1" />
    <v-rating
      :value="this.hotel.reviews[0].rate"
      color="amber"
      dense
      half-increments
      readonly
      size="0"
      ><template v-slot:item="props">
        <v-icon color="green" size="12">
          {{ props.isFilled ? 'mdi-circle' : 'mdi-circle-outline' }}
        </v-icon>
      </template></v-rating
    >

    <div
      class="
        of
        tw-text-title
        text-preview
        tw-mt-1
        tw-ml-1
      "
    >
      of
      {{
        this.hotel.reviews[0].reviewCount
      }}
      reviews
    </div>
  </v-row>
</template>

<script>
import TripAdvisorIcon from '@/assets/inspirationPage/trip-advisor.svg';
export default {
  props: [ 'hotel' ],
  components: {
    TripAdvisorIcon,
  },
};
</script>

<style scoped>
.provisor {
  height: 12px;
  margin-right: 1px;
}
.text-preview {
  font-size: 10px;
  font-family: 'SFProTextMedium';
}

.of {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  overflow-wrap: anywhere;
  white-space: nowrap;
  padding-top: 0;
}
</style>
