<template>
  <div class="flights-passenger-category-item tw-flex tw-justify-between tw-items-center tw-mb-4">
    <div class="tw-flex tw-items-center">
      <div class="tw-w-5 tw-mr-3 tw-text-primary">
        <slot name="icon"></slot>
      </div>
      <div>
        <h4 class="tw-text-primary tw-mb-1">{{label}}</h4>
        <h4 class="tw-text-xs tw-text-gray-main">{{description}}</h4>
      </div>
    </div>
    <div class="tw-flex tw-items-center">
      <v-btn
      x-small
      fab
      class="tw-p-0"
      :disabled="min === value"
      @click="$emit('input', value - 1)"
      ><v-icon x-small>mdi-minus</v-icon></v-btn>
      <div
      class="tw-w-10 tw-text-lg tw-font-semibold tw-text-center"
      >{{value}}
      </div>
      <v-btn
      x-small
      fab
      class="tw-p-0"
      :disabled="blocked"
      @click="$emit('input', value + 1)"
      ><v-icon>mdi-plus</v-icon></v-btn>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label: String,
    description: String,
    value: Number, 
    min: Number,
    blocked: Boolean,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
      },
    },
    count() {
      return this.value.adults + this.value.children + this.value.infants;
    }
  },
};
</script>

<style>
.flights-passenger-category-item button{
  width: 20px !important;
  height: 20px !important;
}
</style>
