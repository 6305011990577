<template>
  <div class="tw-flex tw-flex-row tw-tw-mt-3">
    <div
      class="
        tw-rounded-full
        tw-h-6
        tw-w-6
        tw-flex
        tw-items-center
        tw-justify-center
        tw-bg-purple
        tw-mt-3
        tw-mr-3
      "
    >
      <slot />
    </div>
    <div class=" tw-text-left tw-flex tw-items-center tw-font-sfprotext-regular leading tw-text-base tw-mt-3">
      {{ description }}
    </div>
  </div>
</template>
<script>
export default {
  props: [ 'description' ]
};
</script>

<style lang="scss" scoped>
.leading {
  letter-spacing: -0.03rem;
}
</style>

