<template>
  <flights-control-container>
    <flights-location-selector
    label="From"
    :location="locationFrom"
    @input="value => $emit('input', 'locationFrom', value)"
    />
    <flights-control-container-divider />
    <flights-location-selector
    label="To"
    :location="locationTo"
    :airportCode="airportCode"
    @input="value => $emit('input', 'locationTo', value)"
    />
  </flights-control-container>
</template>

<script>
import FlightsControlContainer from './FlightsControlContainer.vue';
import FlightsControlContainerDivider from './FlightsControlContainerDivider.vue';
import FlightsLocationSelector from './FlightsLocationSelector.vue';

export default {
  components: { 
    FlightsLocationSelector, 
    FlightsControlContainer, 
    FlightsControlContainerDivider 
  },
  props: {
    locationFrom: String,
    locationTo: String,
    airportCode: String
  },
  computed: {
  },
};
</script>
