<template>
  <div class="modal date-modal">
    <div
      class="modal-title tw-mt-2 tw-text-center tw-font-poppins tw-font-semibold tw-text-title tw-leading-9"
    >
      Please select the date and time for your booking
    </div>
    <div class="tw-flex-grow">
      <div class="section">
        <div class="form-label">
          Select your date
        </div>

        <div class="tw-flex tw-flex-row form-container  tw-rounded-xl tw-pl-3">
          <home-date-picker
            class="form-label"
            label="From"
            v-model="selectedDate"
          />
          <calendar-icon class="tw-w-6 tw-h-6 tw-m-auto tw-text-primary" />
        </div>
      </div>

      <!-- <div v-else class="section tw-flex tw-flex-col">
        <div>{{ this.monthVal }} {{ this.yearVal }}</div>
        <div
          class="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-3 content-center"
        >
          <template v-for="(date, index) in this.datesArr">
            <button
              @click="selectedDate = date"
              :key="`date_${index}`"
              class="dayButton tw-mt-2 tw-mb-2"
              v-on:click="selected"
              :class="{ color: active }"
            >
              <div class="dateCircles tw-rounded-full tw-shadow-md ml-1">
                {{ date.format('DD') }}
              </div>
            </button>
          </template>
        </div>
      </div> -->

      <div class="section">
        <div class="time-label">Time</div>
        <div class="select-container tw-rounded-xl">
          <v-select flat solo v-model="selectedTime" :items="selectableTimes">
          </v-select>
        </div>
      </div>

      <div class="section">
        <div class="guests-label">number of people</div>

        <div class="counter">
          <div
            class="tw-flex-grow tw-text-title tw-select-none  tw-text-2xl tw-leading-6 tw-text-left tw-tracking-tighter"
          >
            {{ quantity }} Guest<template v-if="quantity > 1">s</template>
          </div>
          <button
            v-if="quantity != 1"
            type="button"
            v-on:click="
              () => {
                quantity -= 1;
              }
            "
            class="counter-button"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.55469 9.99902H15.4436"
                stroke="#D62A53"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div class="quantity">{{ quantity }}</div>
          <button
            type="button"
            v-on:click="
              () => {
                quantity += 1;
              }
            "
            class="counter-button"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 4.55469V15.4436"
                stroke="#D62A53"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.55469 9.99902H15.4436"
                stroke="#D62A53"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="tw-pb-3 tw-flex tw-flex-row">
      <v-btn
        @click="
          () => {
            this.updateDateTime();
          }
        "
        :disabled="!(selectedDate && selectedTime)"
        color="primary"
        class="tw-w-6/12 updateButton"
      >
        <CartIcon class="cartIcon" /> Add to cart
      </v-btn>
    </div>

    <div class=" tw-py-0 tw-flex tw-flex-row">
      <v-btn @click="$emit('close')" class="tw-w-14 content-center closeButton">
        Cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import HomeDatePicker from '@/views/landingPage/landingPageComponents/HomeDatePicker.vue';
import CalendarIcon from '@/assets/bookingPage/flights/calendar.svg';

export default {
  name: 'Modal',
  data() {
    return {
      selectedDate: null,
      selectedTime: null,
      quantity: 1,
      active: false,
      selectableTimes: [
        '9:00',
        '9:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30'
      ]
    };
  },
  props: {
    item: {
      type: Object
    }
  },
  components: {
    CartIcon,
    HomeDatePicker,
    CalendarIcon
  },
  computed: {
    ...mapGetters([ 'activitiesInCart' ]),
    datesArr() {
      const { from, to } = this.$store.state.search;
      const startDate = moment(from);
      const endDate = moment(to);
      let date = startDate;
      const dates = [];
      if (startDate && endDate) {
        while (date.isSameOrBefore(endDate)) {
          dates.push(date.clone());
          date = date.add(1, 'day');
        }
      }
      return dates;
    },
    monthVal() {
      const { from } = this.$store.state.search;
      const month = moment(from).format('MMMM');
      return month;
    },
    yearVal() {
      const { from } = this.$store.state.search;
      const year = moment(from).format('YYYY');
      return year;
    }
  },
  methods: {
    updateDateTime() {
      const [ hour, minute ] = this.selectedTime.split(':');
      this.$emit(
        'update',
        this.item,
        this.selectedDate,
        { hour: hour, minute: minute },
        this.quantity
      );
    },
    selected: function() {
      this.active = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';
.mainTitle {
  font-size: 14px;
}

.updateButton {
  font-size: 20px;
  border-radius: 104px;
  text-transform: none;
  margin: 0px;
  height: 54px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  height: 3.5rem !important;
  @include respond-to(xs) {
    font-size: 15px;
  }
}

.form-label {
  font-size: 15px;
  margin: auto;
  color: #747378;
  font-style: bold;
  margin-bottom: 13px;
}

.closeButton {
  color: #747378;
  background-color: transparent !important;
  border: unset;
  border-color: transparent !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  font-family: 'MontserratSemiBold';
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  width: 100%;
  cursor: pointer;
}

.time-label {
  color: black;
  font-size: 14px;
  font-family: 'RedHatDisplayMedium';
}

.time-label,
.form-label,
.guests-label {
  font-family: PoppinsMedium;
  font-size: 18px;
  line-height: 18px;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Paragraph */

  color: #747378;

  margin-bottom: 16px;
}

.form-container {
  background-color: #e5e5e5;
  max-height: 54px;
  padding: 0 20px !important;
}

select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 96%;
}

.dateCircles {
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  box-shadow: none !important;
  background: #ffe4ec;
  color: #d62a53;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: MontserratMedium;
  font-size: 18px;   
}

.dateCircles:hover {
  background-color: $primary;
}

.experienceName {
  font-family: 'RedHatDisplayBold';
  font-size: 14px;
}

.dayButton {
  color: black;
  text-align: center;
  height: 30px;
  width: 50px;
}

.cartIcon {
  margin-right: 5px;
}

.modal-title {
  font-size: 32px;
  width: 432px;
  margin: auto;
  padding-bottom: 8px;
  max-width: 100%;
}

.modal {
  height: calc(100vh - 32px) !important;
  width: 100% !important;
  max-height: 34vw !important;
  max-width: 26.67% !important;

  @media (max-width: 1880px), (max-height: 652px) {
    width: 512px !important;
    height: 666px !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
  }

  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 40px !important;

  .v-btn {
    width: 100%;
    font-family: 'MontserratSemiBold';
    text-align: center;
    letter-spacing: -0.02em;
    border-radius: 104px !important;
    font-size: 18px !important;
    height: 54px !important;
    svg {
      width: 16px;
    }
  }
}

.v-select {
  .v-text-field__details {
    display: none;
  }
}

.section {
  height: 88px;
  margin: 24px 0 0 0;
  display: inline-block;
  width: 100%;
}

.select-container {
  background: #ffe4ec;
  color: #d62a53;
  height: 54px;
}

.counter {
  @apply tw-self-center tw-font-bold tw-flex tw-flex-row tw-items-center tw-gap-12 tw-mb-2;

  @apply tw-gap-2 tw-mb-0;

  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 55px;

  .quantity {
    min-width: 18px;
    user-select: none;
  }

  text-align: center;

  /* Primary */

  color: #d62a53;
}

.counter {
  svg {
    margin: auto;
  }
}

.counter-button,
.remove-btn {
  background: #ffe4ec;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  /* Primary */
  color: #d62a53;
}
</style>
