<template>
  <div
    class="section bg-image hidden-xs-only"
 
  >
    <div class="setBackground map-container">
      
      <div
        class="tw-justify-center tw-min-h-screen"
      >
      <breadcrumbs :city="city" class="tw-mt-32 tw-z-40 tw-py-0 tw-ml-16 tw-absolute"/>
        <gmap-map
           :center="currentCityCoordinates"
            :options="mapOption"
            style="width: 100%; height: 60vh; tw-relative"
            ref="map"
        >
         <div class="">
        </div>
        <gmap-custom-marker 
                :marker="{
                  lat: this.originCoordinates[Object.keys(this.originCoordinates)[0]], 
                  lng: this.originCoordinates[Object.keys(this.originCoordinates)[1]]}"
                >
                 <div class="icon-container">
                <img
                  class="marker"
                  :src="
                    require(`../../assets/destinationPage/balloon-icon.png`)"
                />
              </div>
            </gmap-custom-marker>
             <gmap-custom-marker 
                :marker="{
                  lat: this.destinationCoordinates[Object.keys(this.destinationCoordinates)[0]], 
                  lng: this.destinationCoordinates[Object.keys(this.destinationCoordinates)[1]]}"
                >
                 <div class="icon-container">
                <img
                  class="marker"
                  :src="
                    require(`../../assets/destinationPage/balloon-icon-white.png`)"
                />
              </div>
            </gmap-custom-marker>
        <DirectionRenderer
          :origin="originCity"
          :destination="destinationCity" />
        </gmap-map>
      </div>
    </div>
  </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import DirectionRenderer from './DirectionRenderer.js';
import Breadcrumbs from '@/views/layouts/common/Breadcrumbs.vue';

export default {
  name: 'MapCityTab',
  data() {
    return {
      originCity: this.$route.query.city,
      destinationCity: this.$route.params.cityName,
      originCoordinates: { lat: 0, lng: 0 },
      destinationCoordinates: { lat: 0, lng: 0 },
      originCountry: '',
      destinationCountry: '',
      marker: {
        lat: 0,
        lng: 0,
      },
      places: [],
      mapOption: {
        mapId: '6a1cdf7cb1036aae',
        zoom: 4,
        minZoom: 3,
        clickableIcons: false,
        disableDefaultUI: true,
        keyboardShortcuts: false,
        center: { lat:50, lng:20 },
      },
    };
  },
  components: {
    GmapCustomMarker,
    DirectionRenderer,
    Breadcrumbs
  },
  props: [ 'city' ],
  methods: {
    getOriginCityCoordinates() {
      const addressObject = {
        address_line_1: '',
        address_line_2: '',
        city: this.originCity,
        state: '', // province also valid
        zip_code: '', // postal_code also valid
        country: '',
      };
      this.$geocoder.send(addressObject, response => {
        this.originCoordinates = response?.results[0]?.geometry.location;
        this.originCountry = response?.results[0]?.formatted_address.split(
          ', '
        )[1]
          ? `, ${response?.results[0]?.formatted_address.split(', ')[1]}`
          : '';
      });
      this.places.push(this.originCity);
    },
    getDestinationCityCoordinates() {
      const addressObject = {
        address_line_1: '',
        address_line_2: '',
        city: this.destinationCity,
        state: '', // province also valid
        zip_code: '', // postal_code also valid
        country: '',
      };
      this.$geocoder.send(addressObject, response => {
        this.destinationCoordinates = response?.results[0]?.geometry.location;
        this.destinationCountry = response?.results[0]?.formatted_address.split(
          ', '
        )[1]
          ? `, ${response?.results[0]?.formatted_address.split(', ')[1]}`
          : '';
      });
      this.places.push(this.destinationCity);
    },
  },
  computed: {
    currentCityCoordinates() {
      return {
        lat: this.destinationCoordinates[Object.keys(this.destinationCoordinates)[0]] + 10,
        lng: this.destinationCoordinates[Object.keys(this.destinationCoordinates)[1]] + 20
      };
    }
  },
  mounted() {
    this.getOriginCityCoordinates();
    this.getDestinationCityCoordinates();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.bg-image {
  background-position: center;
  background-size: cover;
  height: 60vh;
}

.setBackground {
  z-index: 10;
  background: linear-gradient(180deg, #000000 -9.62%, rgba(0, 0, 0, 0) 100%);
  height: 15vh;
}

  .map-container {
    background-color: aqua;
    flex: 4 1 0;
    height: 100%;
    width: 100%;
    bottom: 250px;
    right: 0;
    top: 10px;
  }


.min-h-screen {
  min-height: 100vh;
}

.vue-map-container::v-deep .gmnoprint {
  display: none !important;
}

.vue-map-container::v-deep a{
  display: none !important;
}

.icon-container {
        width: 17.18vw;
        .marker {
          height: 3.06vw;
          width: 2.29vw;
          object-fit: contain;
          margin: auto;
          cursor: pointer;
        }
      }
</style>

