<template>
  <v-menu
  v-model="menu"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  min-width="auto"
  attach
  bottom
  >
  <template v-slot:activator="{ on, attrs }">
    <div class="tw-flex tw-items-center tw-w-full passenger-selector">
      <passengers-icon class="tw-w-6 tw-h-6 tw-ml-2" />
      <div>
      <div class="label tw-text-left tw-text-gray-main tw-pl-3 tw-uppercase tw-text-xs tw-font-semibold passengerPadding">{{label}}</div>
        <v-text-field
          class="text-field right"
          :value="`${count} ${count > 1 ? 'Passengers' : 'Passenger'}`"
          dense
          flat
          solo
          hide-details
          readonly
          v-bind="attrs"
          v-on="on"
        >
        </v-text-field>
      </div>
    </div>
  </template>
  <div
    class="tw-bg-white tw-rounded-lg tw-p-5 tw-w-72"
  >
    <div class="tw-flex-col">
      <h4 class="tw-font-semibold tw-mb-3">Passengers</h4>
      <flights-passenger-category-item
      label="Adults"
      description="Over 11"
      :min="1"
      :blocked="count === 9"
      v-model="adults"
      >
        <template slot="icon"><adults-icon /></template>
      </flights-passenger-category-item>

      <flights-passenger-category-item
      label="Children"
      description="2 - 11"
      :min="0"
      :blocked="count === 9"
      v-model="children"
      >
        <template slot="icon"><children-icon /></template>
      </flights-passenger-category-item>

      <flights-passenger-category-item
      label="Infants"
      description="Under 2"
      :min="0"
      :blocked="count === 9 || infants >= adults"
      v-model="infants"
      >
        <template slot="icon"><infants-icon /></template>
      </flights-passenger-category-item>
    </div>
  </div>
</v-menu>
</template>
<script>
import PassengersIcon from '@/assets/bookingPage/flights/passengers.svg';
import AdultsIcon from '@/assets/bookingPage/flights/passengers/adults.svg';
import ChildrenIcon from '@/assets/bookingPage/flights/passengers/children.svg';
import InfantsIcon from '@/assets/bookingPage/flights/passengers/infants.svg';
import FlightsPassengerCategoryItem from './FlightsPassengerCategoryItem.vue';

export default {
  components:{
    PassengersIcon,
    AdultsIcon,
    ChildrenIcon,
    InfantsIcon,
    FlightsPassengerCategoryItem,
  },
  props: {
    label: String,
    value: Object, 
    min: String,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    adults: {
      get: function() {
        return this.value.adults;
      },
      set: function(value) {
        this.updatePassengers('adults', value);
      },
    },
    children: {
      get: function() {
        return this.value.children;
      },
      set: function(value) {
        this.updatePassengers('children', value);
      },
    },
    infants: {
      get: function() {
        return this.value.infants;
      },
      set: function(value) {
        this.updatePassengers('infants', value);
      },
    },
    count() {
      return this.value.adults + this.value.children + this.value.infants;
    },
  },
  methods: {
    updatePassengers(name, value) {
      this.$emit('input', {
        adults: this.value.adults,
        children: this.value.children,
        infants: this.value.infants,
        [name]: value,
      });
    }
  },
};
</script>

<style>
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent;
}

.passenger-selector {
  font-family: 'MontserratSemiBold';
}

.passengerPadding {
 padding-top: 0.3rem;
 margin-bottom: -0.4rem;

}
</style>
