<template>
  <div class="flights-location-selector tw-flex tw-items-center tw-w-full">
    <location-icon class="tw-w-6 tw-h-6 tw-ml-2" />
    <div>
    <div class="label tw-text-left tw-text-gray-main tw-pl-3 tw-uppercase tw-text-xs tw-font-semibold tw-pt-3">{{label}}</div>
      <v-autocomplete
        placeholder="Berlin, Paris..."
        v-model="locationVal"
        :items="cities"
        :search-input.sync="search"
        hide-details
        flat
        auto
        solo
      />
    </div>
  </div>
</template>

<script>
import LocationIcon from '@/assets/bookingPage/flights/location.svg';
import cities from '@/assets/cities.json';

export default {
  components: {
    LocationIcon,
  },
  data: () =>({
    search: '',
    //cities: cities.concat(this.airportCode),
  }),
  props: {
    label: String,
    location: String,
    airportCode : String,
  },
  computed: {
    locationVal: {
      get() { return this.airportCode ? this.airportCode : this.location; },
      set(val) { this.$emit('input', val); },
    },
    cities: function() {
      return this.airportCode ? [ this.airportCode, ...cities ] : cities;
      
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">

.flights-location-selector {
  font-family: 'MontserratSemiBold';
  .v-input  {
    margin-top: -9px !important;
  }
}
</style>
