<template>
  <MatchMedia>
    <div class="card text-left tw-border tw-shadow tw-rounded-xl md:tw-rounded">
      <img
        :src="src"
        class="offer-img tw-rounded-t-xl md:tw-rounded tw-cursor-pointer"
        @click="showPopup = true"
      />

      <div class="tw-flex tw-flex-grow tw-p-6 tw-overflow-hidden">
        <!-- left side -->

        <div class="tw-flex-grow">
          <h4
            class="
              tw-font-poppins
              tw-font-semibold
              tw-text-title
              tw-font-semibold
              tw-text-2xl
              tw-p-0
              tw-pl-0
              tw-text-left
            "
          >
            {{ this.truncate(name, 15) }}
          </h4>
          <div
            class="
              tw-font-montserrat-regular
              tw-text-base
              tw-text-paragraph
              tw-leading-6
              tw-mb-6
              tw-mt-4
              description
            "
          >
            {{ description }}
          </div>
          <div
            class="
              tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-2
            "
            v-if="type !== 'restaurants'"
          >
            <div
              class="
                tw-inline-flex
                tw-font-montserrat-semi-bold
                tw-h-4
                tw-cursor-pointer
                tw-align-middle
                tw-text-primary
                tw-text-end
                btn
              "
              @click="
                type === 'activities' || type === 'restaurants'
                  ? (showModal = true)
                  : addItemToCart()
              "
            >
              <span>
                <CartIcon class="tw-inline-block tw-h-4 tw-w-4" />
                <span class="tw-inline-block tw-h-4 tw-leading-4">{{
                  btnText
                }}</span></span
              >
            </div>
          </div>
        </div>

        <!-- right side -->
        <div class="tw-flex tw-flex-col tw-min-w-max w-24">
          <div
            class="
              tw-text-right
              tw-font-poppins
              tw-font-semibold
              tw-text-title
              tw-font-semibold
              tw-pl-4
              tw-text-2xl
              tw-flex-grow
              tw-leading-8
              tw-h-8
            "
          >
            <template v-if="type !== 'restaurants'">
              <div>{{ priceWithCurrency }}</div>

              <div
                class="
                  tw-text-right
                  tw-font-montserrat-semi-bold
                  tw-text-paragraph
                  tw-font-medium
                  tw-text-sm
                "
              >
                Per Adult
              </div>
            </template>

            <template v-else>
              <div v-if="price < 10">
                €<span class="tw-opacity-50">€€€</span>
              </div>
              <div v-else-if="price < 35">
                €€<span class="tw-opacity-50">€€</span>
              </div>
              <div v-else-if="price < 85">
                €€€<span class="tw-opacity-50">€</span>
              </div>
              <div v-else>€€€€</div>
            </template>
          </div>
          <div
            class="
              tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-2
            "
            v-if="type === 'restaurants'"
          >
            <div
              class="
                tw-pl-2.5
                tw-font-montserrat-semi-bold
                tw-h-4
                tw-cursor-pointer
                tw-align-middle
                tw-text-primary
                tw-text-end
                btn
              "
              @click="
                type === 'activities' || type === 'restaurants'
                  ? (showModal = true)
                  : addItemToCart()
              "
            >
              <span>
                <CartIcon class="tw-inline-block tw-h-4 tw-w-4" />
                <span class="tw-inline-block tw-h-4 tw-leading-4">{{
                  btnText
                }}</span></span
              >
            </div>
          </div>
        </div>

        <transition name="fade" appear>
          <div
            class="modal-overlay"
            v-if="showModal"
            @click="showModal = false"
          ></div>
        </transition>
        <date-modal
          v-if="showModal"
          :item="item"
          @close="closeModal"
          @update="updateModal"
        ></date-modal>
      </div>
    </div>
    <IframePopup
      @togglePopup="
        () => {
          showPopup = !showPopup;
        }
      "
      v-if="showPopup"
      :src="url"
      @addItemToCart="
        type === 'activities' || type === 'restaurants'
          ? (showModal = true)
          : addItemToCart();
        showPopup = !showPopup;
      "
    />
  </MatchMedia>
</template>

<script>
import dateModal from './DateModal.vue';
import { MatchMedia } from 'vue-component-media-queries';
import { mapActions, mapGetters } from 'vuex';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import IframePopup from './components/IframePopup.vue';
import { transformImage } from '@/utils/transformImage';

export default {
  name: 'OfferCard',
  components: {
    CartIcon,
    MatchMedia,
    dateModal,
    IframePopup,
  },
  props: {
    id: String,
    name: String,
    description: String,
    image: String,
    price: Number,
    url: String,
    type: String,
    drawer: Boolean,
    item: Object,
  },
  data: function() {
    return {
      savedInBookmarks: false,
      savedInFavorites: false,
      addedToCart: false,
      showModal: false,
      showPopup: false,
    };
  },
  computed: {
    priceWithCurrency() {
      return `€${this.price}`;
    },
    src() {
      return transformImage(this.image, 500, 'auto:best');
    },
    ...mapGetters([ 'totalCartCount', 'drawerState' ]),
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        this.$store.commit('toggleDrawer', v);
      },
    },
    btnText() {
      return this.type === 'restaurants' ? 'Reserve Now' : 'Add to cart';
    },
  },

  methods: {
    ...mapActions([
      'addExperienceToCart',
      'updateDates',
      'removeActivityOrRestaurant',
    ]),
    truncate: function(str, n) {
      return str.length > n ? str.substr(0, n) + '...' : str;
    },
    openItemLink() {
      window.open(this.url, '_blank');
    },
    addItemToCart() {
      this.addExperienceToCart({
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.price,
        quantity: 1,
        details: { description: this.description },
        type: this.type,
      });
      this.drawerState = !this.drawerState;
    },
    closeModal() {
      this.showModal = false;
    },
    updateModal(name, date, time = null, quantity = 1) {
      if (this.type !== 'activities') {
        quantity = 1;
      }
      this.removeActivityOrRestaurant({ name: this.name });
      this.addExperienceToCart({
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.price,
        details: { description: this.description },
        quantity: quantity,
        type: this.type,
        date,
      });
      this.updateDates({ name: this.name, date, time });
      this.drawerState = !this.drawerState;
      this.showModal = false;
    },
    showInstagram() {
      window.open('https://instagram.com/' + this.influencerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06) !important;
  border-radius: 18px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 530px;

  h4,
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.offer-img {
  @apply tw-w-full tw-object-cover;
  height: 16.35vw;
  max-height: 332px;
  border-radius: 18px 18px 0px 0px;
  max-width: 520px;
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-80;
  z-index: 98;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.v-rating button {
  padding-left: 0 !important;
}

.btn {
  background: #d62a53;
  border-radius: 90px;
  width: 160px;
  height: 34px;
  color: white;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  > span {
    display: flex;
    gap: 8px;
  }
  & > * {
    margin: auto;
  }
}

.btn:hover {
  opacity: 0.9;
}
.btn:active {
  opacity: 0.75;
}
</style>
