<template>
  <div class="outer">
    <h1>Deals</h1>
    <div class="rooms-container">
      <rooms-list
        :hideOptions="true"
        :page="page"
        @close="
          () => {
            $emit('close');
          }
        "
        :hotel="hotel"
        @addToCard="
          (hotel) => {
            $emit('addToCard', hotel);
          }
        "
      ></rooms-list>
    </div>
    <div class="showing">
      <div>
        Showing
        {{ page * 30 - 29 }}
        <template v-if="page * 30 < dealsCount">- {{ page * 30 }}</template>
        <template v-else-if="dealsCount > 1">- {{ dealsCount }}</template>
        of {{ dealsCount }} deals
      </div>
      <div class="tw-flex tw-ml-auto tw-gap-3 tw-select-none">
        <div
          @click="
            () => {
              if (page > 1) page--;
            }
          "
          class="
              tw-cursor-pointer arrow
              tw-bg-paragraph tw-bg-opacity-10
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
        >
          <v-icon color="black">mdi-chevron-left </v-icon>
        </div>
        <div
          v-for="index in pagesCount"
          :key="index"
          class="page-number"
          v-bind:class="{ activePage: index === page }"
          @click="
            () => {
              selectedImageIndex = null;
              page = index;
            }
          "
        >
          {{ index }}
        </div>
        <div
          @click="
            () => {
              if (pagesCount > page) page++;
            }
          "
          class="
              arrow tw-cursor-pointer
              tw-bg-paragraph tw-bg-opacity-10
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
        >
          <v-icon color="black">mdi-chevron-right </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomsList from '../RoomsList.vue';

export default {
  data: () => ({
    page: 1,
    deals: [ 1, 2, 3, 4, 5 ]
  }),
  props: {
    hotel: Object
  },
  components: {
    RoomsList
  },
  methods: {
    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    }
  },
  computed: {
    pagesCount: {
      get() {
        let count = 0;
        this.hotel.rooms.forEach(room => {
          count += room.rates.length;
        });
        return this.roundUp(count / 30, 0);
      }
    },
    dealsCount: {
      get() {
        let count = 0;
        this.hotel.rooms.forEach(room => {
          count += room.rates.length;
        });
        return count;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.outer {
  padding: 48px 32px;
  text-align: left;
}

.rooms-container {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

h1 {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #28262e;
  text-align: left;
  margin: 16px 0 24px 0;
}

.showing {
  width: 100%;
  text-align: start;
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  color: #28262e;
  margin-top: 28px;
}
.page-number {
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-family: PoppinsMedium;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.activePage {
  background: #d62a53;
  color: #ffffff;
}

.arrow {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.v-icon {
  font-size: 16px !important;
}
</style>
